import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Table } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import { createBatchInstallment } from 'services/message.service';
import papa from 'papaparse';
import { json2csv } from 'json-2-csv';
import { useNavigate } from 'react-router-dom';
import { Alert, message, notification } from 'antd/lib';
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { downloadCSVFile } from '../../../services/file.service';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const UploadInstallmentPage = () => {
    const [tableData, setTableData]: any = useState([]);
    const [showDownloadBtn, setShowDownloadBtn] = useState(false);
    const [erroNumber, setErrorNumber] = useState(0);
    const [csv, setCsv] = useState('');
    const [successNumber, setSuccessNumber] = useState(0);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const {
        request: createBatchInstallmentRequest,
        loading,
        result,
    } = useApi({
        api: createBatchInstallment,
    });

    const uploadCsvData = useCallback(
        async (csvResult: []) => {
            try {
                const res = await createBatchInstallmentRequest({
                    token: token,
                    res: csvResult,
                });
                const { data, error } = res;
                if (!error) {
                    let errorRecords = data.error;
                    if (errorRecords.length === 0) {
                        notification.success({
                            message: 'Success',
                            description: 'All rows are successfully uploaded',
                        });

                        navigate('/installments');
                        return;
                    } else if (data.success?.length > 0) {
                        notification.warning({
                            message: 'Error',
                            description: 'Some rows encountered error',
                            duration: 3,
                        });
                    } else {
                        notification.error({
                            message: 'Error',
                            description: 'Error for all rows',
                            duration: 3,
                        });
                    }

                    setSuccessNumber(data.success?.length);
                    await handleFailedRecords(errorRecords, 'internal error');
                } else {
                    setSuccessNumber(0);
                    notification.error({
                        message: 'Error',
                        description: error.message,
                        duration: 3,
                    });
                    await handleFailedRecords(csvResult, error.message);
                }
            } catch (error) {
                throw error;
            }
        },
        [token, navigate, createBatchInstallmentRequest],
    );

    const handleFailedRecords = async (errorRecords: any[], common_err: string) => {
        const failedRecords = errorRecords.map(d => {
            const schedule = d.schedules?.length === 0 ? null : d.schedules[0];
            const dt = schedule ? schedule.scheduled_at.split('-') : null;
            return {
                user_id: d.user_id,
                type: d.type,
                amount: schedule ? schedule.amount : '',
                scheduled_at: dt ? dt[2] + '-' + dt[1] + '-' + dt[0] : '',
                failed_response: d.error_msg ? d.error_msg : common_err,
            };
        });

        const csvData = await json2csv(failedRecords);
        setCsv(csvData);
        setTableData(failedRecords);
        setErrorNumber(failedRecords.length);
        setShowDownloadBtn(true);
    };

    const downloadCSVData = () => {
        const csvData = String(csv);
        downloadCSVFile(csvData, 'failed_installments');
    };

    const downloadTemplate = () => {
        let tempData = `user_id,type,amount,schedule
f4f54946-1132-4fa0-8a86-f4ec9798addd,BIKER_GEAR,10,"2024-07-09;2024-08-09"
f4f54946-1132-4fa0-8a86-f4ec9798addd,INSURANCE,20,2024-09-05
f4f54946-1132-4fa0-8a86-f4ec9798addd,BIKER_GEAR,10,2024-08-10`;

        downloadCSVFile(tempData, 'installments_template_v1');
    };

    const columns = [
        {
            title: 'user_id',
            dataIndex: 'user_id',
            key: 'user_id',
        },
        {
            title: 'type',
            key: 'type',
            render: (installment: any) => installment?.type,
        },
        {
            title: 'amount',
            key: 'amount',
            render: (installment: any) => `PHP ${installment?.amount?.toFixed(2)}`,
        },
        {
            title: 'schedule',
            key: 'scheduled_at',
            render: (installment: any) => installment?.scheduled_at,
        },
        {
            title: 'failed_response',
            key: 'failed_response',
            render: (installment: any) => installment?.failed_response,
        },
    ];

    const handleFile = (event: any) => {
        setShowDownloadBtn(false);
        papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result: any) {
                const max_rows = 3000;
                if (result.data.length > max_rows) {
                    // Maximum of 3000 rows per upload
                    notification.error({
                        message: 'Error',
                        description: `Maximum of ${max_rows} Rows per upload`,
                        duration: 3,
                    });

                    return;
                }
                try {
                    let arrayObj = result.data.map((d: any) => {
                        const newSchedules = d.schedule.split(';');
                        let schedules = newSchedules.map((schedule: any) => {
                            return {
                                amount: Number(d.amount),
                                scheduled_at: schedule,
                            };
                        });
                        return {
                            user_id: d.user_id,
                            type: d.type,
                            schedules: schedules,
                        };
                    });
                    uploadCsvData(arrayObj);
                } catch (err) {
                    message.error('Please upload a CSV file with a valid template.');
                }
            },
        });
    };

    const handleFileUpload = (event: any) => {
        (hiddenFileInput?.current as any)?.click();
    };

    return (
        <ListLayout goBackLink="/installments" title="Create New Installments">
            <div className="flex justify-between">
                <Alert
                    showIcon
                    type="warning"
                    message="Please make sure you follow the correct format before uploading the
                    CSV file."
                />

                <div className="flex flex-row gap-1">
                    {hasActionAccess(PagesEnum.INSTALLMENT) && (
                        <div>
                            <input
                                hidden
                                id="upload-file"
                                type="file"
                                accept=".csv"
                                name="file"
                                ref={hiddenFileInput}
                                onChange={handleFile}
                            ></input>
                            <Button type="primary" onClick={handleFileUpload} icon={<UploadOutlined />}>
                                Upload CSV
                            </Button>
                        </div>
                    )}
                    <Button type="ghost" target="_blank" onClick={() => downloadTemplate()} icon={<DownloadOutlined />}>
                        Download Template
                    </Button>
                </div>
            </div>
            {showDownloadBtn ? (
                <div style={{ border: '1px solid #ADB6B9' }} className="flex justify-between items-center m-2 p-4">
                    <div>
                        <ul className="text-sm font-bold p-0">
                            Upload .csv error
                            <li className="block font-medium text-base">{erroNumber} Number of rows have error </li>
                            <li className="block font-medium text-base">{successNumber} Number of rows have success</li>
                        </ul>
                    </div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                border: '1px solid #D9D9D9',
                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                            }}
                            className="bg-inherit px-4 py-2"
                            onClick={() => downloadCSVData()}
                            id="text-button"
                        >
                            Download Failed Rows
                        </button>
                    </div>
                </div>
            ) : null}
            <Table columns={columns} loading={loading} dataSource={tableData} />
        </ListLayout>
    );
};

export default UploadInstallmentPage;
