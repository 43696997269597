import React, { useState } from 'react';
import { Select } from 'antd/lib';
import Text from 'components/basic/Typography/Text';
import { Button, Input } from 'components/basic';
import { DatePicker } from 'antd/lib';
import moment from 'moment';

const { RangePicker } = DatePicker;

const { Option } = Select;

interface DriverFilterProps {
    onFilterSubmit: (values: {
        field: string;
        keyword: string;
        status: string;
        created_at_gte: string;
        created_at_lte: string;
    }) => void;
    onFilterReset?: () => void;
    loading: boolean | undefined;
}

const CustomerTransactionFilter: React.FC<DriverFilterProps> = ({ onFilterSubmit, onFilterReset, loading }) => {
    const [selectedField, setSelectedField] = useState('customer_id');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedGtDate, setSelectedGtDate] = useState('');
    const [selectedLtDate, setSelectedLtDate] = useState('');

    const handleFieldChange = (value: string) => {
        setSelectedField(value);
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSearchKeyword(inputValue);
    };

    const handleFilterSubmit = () => {
        let kw = searchKeyword;
        onFilterSubmit({
            field: selectedField,
            keyword: kw,
            status: selectedStatus,
            created_at_gte: selectedGtDate,
            created_at_lte: selectedLtDate,
        });
    };

    const handleFilterReset = () => {
        setSelectedField('customer_id');
        setSelectedStatus('');
        setSearchKeyword('');
        setSelectedGtDate('');
        setSelectedLtDate('');
        onFilterSubmit({
            field: 'customer_id',
            status: '',
            keyword: '',
            created_at_gte: '',
            created_at_lte: '',
        });
    };

    const disabledDate = (current: any) => {
        // Disable dates after today (future dates)
        return current && current > moment().endOf('day');
    };

    return (
        <div className="flex items-end gap-2">
            <div className="flex flex-1 items-center gap-2">
                <div className="block w-full">
                    <Text type="label">Search by customer ID or Trip ID</Text>
                    <div className="flex gap-1">
                        <Select className="w-48" value={selectedField} onChange={handleFieldChange}>
                            <Option value="customer_id">Customer ID</Option>
                            <Option value="trip_request_id">Trip ID</Option>
                        </Select>
                        <Input
                            className="rounded-full p-lg"
                            value={searchKeyword}
                            onChange={handleKeywordChange}
                            placeholder="Enter search keyword..."
                            onPressEnter={handleFilterSubmit}
                        />
                    </div>
                </div>
                <div className="block">
                    <Text type="label">Select Status</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'VOIDED', label: 'Failed' },
                            { value: 'AWAITAUTH', label: 'Pending' },
                            { value: 'AUTHORIZED', label: 'Authorized' },
                            { value: 'CAPTURED', label: 'Success' },
                            { value: 'REFUNDED', label: 'Refunded' },
                            { value: 'REFUND_REQUESTED', label: 'Refund Requested' },
                            { value: 'REFUND_FAILED', label: 'Refund Failed' },
                            { value: 'CAPTURE_FAILED', label: 'Capture Failed' },
                            { value: 'AUTO_VOIDED', label: 'Failed' },
                        ]}
                    />
                </div>
                <div>
                    <Text type="label">Filter By Date</Text>
                    <RangePicker
                        format="YYYY-MM-DD"
                        value={[
                            !!selectedLtDate ? moment(selectedLtDate) : null,
                            !!selectedGtDate ? moment(selectedGtDate) : null,
                        ]}
                        onChange={(value, dateString) => {
                            setSelectedLtDate(dateString[1]);
                            setSelectedGtDate(dateString[0]);
                        }}
                        disabledDate={disabledDate}
                    />
                </div>
            </div>
            <div className="flex items-center gap-1">
                <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default CustomerTransactionFilter;
