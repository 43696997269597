import { Button, Card, Modal, Skeleton, Tabs, Tooltip, InputNumber, Select, notification, Form, Input } from 'antd/lib';
import { CustomNotification, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import useModal from 'hooks/useModal';
import useMount from 'hooks/useMount';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getInternalBalances, addFunds } from 'services/message.service';
import { formatNumberToAmount } from 'services/number.service';
import { EyeInvisibleOutlined, ReloadOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { fundCode } from './constants';
import { getKeyByValue } from 'utils/objectOperations';

const LedgerAccounts = () => {
    const [data, setData] = useState<{ revenue: any; funds: any; sales: any; accounts: any }>();
    const confirmationModal = useModal();
    const addModal = useModal();
    const confirmationTranModal = useModal();
    const [blur, setBlur] = useState(true);
    const [addValue, setAddValue] = useState({
        type: '',
        amount: '',
    });
    const [isButtonDisabled, setButtonDisabled] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [latestDate, setLatestDate] = useState(moment().format('ddd Do MMM YYYY, h:mm a'));
    const navigate = useNavigate();

    useMount(() => {
        setBlur(true);
        fetchInternalBalances();
    });

    const handleAddFunds = async () => {
        const code = getKeyByValue(fundCode, addValue.type);
        const res = await assignAddFunds({
            account: code,
            amount: addValue.amount,
        });
        if (res.status === 'SUCCESS') {
            showSuccessNotification('Successfully Added Funds.');
            let key = code?.split('_')[1] ?? '';
            if (data && key && res.data) {
                setData({
                    ...data,
                    funds: {
                        ...data.funds,
                        [key]: res.summary.balance_after,
                    },
                });
            }
            fetchInternalBalances();
        } else {
            showErrorNotification(res.error.message);
        }
    };

    const { request, loading } = useApi({
        api: getInternalBalances,
    });

    const fetchInternalBalances = useCallback(async () => {
        const res = await request();
        if (!res.error) {
            setData(res.data);
            setLatestDate(moment().format('ddd Do MMM YYYY, h:mm a'));
            if (!isConfirmed) {
                confirmationModal.show();
            }
        } else {
            setBlur(true);
            notification.info({
                key: 'error',
                message: 'Error',
                description: 'Account Balances will be available shortly. Please try again later.',
                onClose: () => navigate('/'),
            });
        }
    }, [request, isConfirmed, navigate, confirmationModal]);

    const showSuccessNotification = (message: string) => {
        CustomNotification({
            type: 'success',
            message: 'Success',
            description: message,
        });
    };

    const showErrorNotification = (message: string) => {
        CustomNotification({
            type: 'error',
            message: 'Error',
            description: message,
        });
    };
    const { revenue, funds, sales, accounts } = data || {};
    const { installments_gear, installments_insurance, service_fee } = revenue || {};
    const { promo = 0, incentives = 0, manual = 0 } = funds || {};
    const {
        voucher_funds_gcash = 0,
        voucher_funds_maya = 0,
        voucher_direct_funds_gcash = 0,
        voucher_direct_funds_maya = 0,
        cashless = 0,
        internal = 0,
    } = sales || {};
    const { driver_cash = 0, driver_credit = 0, gcash_cashout_fund = 0 } = accounts || {};
    const [form] = Form.useForm();

    const sensitiveContentStyle = useMemo(() => {
        if (blur) {
            return {
                filter: 'blur(5px)',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
            };
        }
    }, [blur]);

    const onFinishForAddModal = (values: any) => {
        setButtonDisabled(true);
        setAddValue(prev => ({ ...prev, amount: values.amount }));
        addModal.close();
        confirmationTranModal.show();
    };

    const onFinishFailedForAddModal = () => {
        addModal.close();
        setButtonDisabled(true);
        form.resetFields();
    };
    const onFinishForConfirmModal = () => {
        handleAddFunds();
        confirmationTranModal.close();
        form.resetFields();
    };

    const onFinishFailedForConfirmModal = () => {
        confirmationTranModal.close();
    };

    const addClick = (event: any) => {
        let fundtype = '';
        switch (event.target?.id) {
            case 'marketing_promo_fund':
                fundtype = 'Marketing Promo Fund';
                break;
            case 'driver_incentives_fund':
                fundtype = 'Driver Incentives Fund';
                break;
            case 'manual_compensation':
                fundtype = 'Manual Compensation';
                break;
            case 'voucher_direct_gcash':
                fundtype = 'Funds Voucher Direct Gcash';
                break;
            case 'voucher_direct_maya':
                fundtype = 'Funds Voucher Direct Maya';
                break;
            case 'voucher':
                fundtype = 'Voucher';
                break;
        }
        // form.setFieldValue("addFunds", fundtype)
        form.setFieldValue('addValue', fundtype);
        setAddValue(() => ({
            type: fundtype,
            amount: '',
        }));
        addModal.show();
    };
    const { request: assignAddFunds } = useApi({ api: addFunds });

    const validateNumber = (_: any, value: number) => {
        // Custom validation function
        if (value < 1000 || value > 50000000) {
            setButtonDisabled(true);
            return Promise.reject('Number must be between 1000 and 50000000');
        } else {
            setButtonDisabled(false);
            return Promise.resolve();
        }
    };

    return (
        <div style={sensitiveContentStyle}>
            <Text type="heading">Ledger Accounts</Text>
            <Tabs
                defaultActiveKey="1"
                className="gap-2"
                tabBarGutter={50}
                tabBarExtraContent={
                    <div className="flex items-center gap-2">
                        <Text>{`Balance as of ${latestDate}`}</Text>
                        <Button icon={<ReloadOutlined />} onClick={() => fetchInternalBalances()}>
                            Refresh Data
                        </Button>
                    </div>
                }
                items={[
                    {
                        label: (
                            <div className="flex flex-row gap-0 items-center">
                                <Text className="uppercase">Revenue Accounts</Text>
                            </div>
                        ),
                        key: 'revenue_accounts',
                        children: (
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Angkas Service Fee
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(service_fee?.total || 0)}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                >
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Collected</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(service_fee?.collected || 0)}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Uncollected</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(service_fee?.uncollected || 0)}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                </Card>
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Installments Revenue
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(
                                                        installments_gear?.total ||
                                                            0 + installments_insurance?.total ||
                                                            0,
                                                    )}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                >
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Collected Biker Gear</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(installments_gear?.collected || 0)}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Collected Insurance</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(installments_insurance?.collected || 0)}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Uncollected</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(
                                                    installments_gear?.uncollected ||
                                                        0 + installments_insurance?.uncollected ||
                                                        0,
                                                )}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                </Card>
                            </div>
                        ),
                    },
                    {
                        label: (
                            <div className="flex flex-row gap-0 items-center">
                                <Text className="uppercase">Fund Sources</Text>
                            </div>
                        ),
                        key: 'fund_sources',
                        children: (
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Marketing Promos Fund
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(promo)}`}
                                                    <img
                                                        id="marketing_promo_fund"
                                                        onClick={addClick}
                                                        src="/images/add.png.png"
                                                        className="ml-2"
                                                    />
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                ></Card>
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Driver Incentives Fund
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(incentives)}`}
                                                    <img
                                                        id="driver_incentives_fund"
                                                        onClick={addClick}
                                                        src="/images/add.png.png"
                                                        className="ml-2"
                                                    />
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                ></Card>
                                <Card
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Manual Compensation
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(manual)}`}
                                                    <img
                                                        id="manual_compensation"
                                                        onClick={addClick}
                                                        src="/images/add.png.png"
                                                        className="ml-2"
                                                    />
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                ></Card>
                            </div>
                        ),
                    },
                    {
                        label: (
                            <div className="flex flex-row gap-0 items-center">
                                <Text className="uppercase">Sales</Text>
                            </div>
                        ),
                        key: 'sales',
                        children: (
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Cashless Rides
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(cashless)}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                >
                                    <Text className="italic">Not from Bank Statement</Text>
                                </Card>
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Voucher Sales
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(
                                                        voucher_funds_gcash + voucher_funds_maya,
                                                    )}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                >
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Gcash</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(voucher_funds_gcash)}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Maya</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(voucher_funds_maya)}`}
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <Text className="italic">Not from Bank Statement</Text>
                                </Card>
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Voucher Direct (e-wallet)
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(
                                                        voucher_direct_funds_gcash + voucher_direct_funds_maya,
                                                    )}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                >
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Gcash</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(voucher_direct_funds_gcash)}`}
                                                <img
                                                    id="voucher_direct_gcash"
                                                    onClick={addClick}
                                                    src="/images/add.png.png"
                                                    className="ml-2 mb-2"
                                                />
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <div className="grid grid-cols-2 gap-2">
                                        <Text>Maya</Text>
                                        {!loading ? (
                                            <Text className="text-right">
                                                {`PHP ${formatNumberToAmount(voucher_direct_funds_maya)}`}
                                                <img
                                                    id="voucher_direct_maya"
                                                    onClick={addClick}
                                                    src="/images/add.png.png"
                                                    className="ml-2"
                                                />
                                            </Text>
                                        ) : (
                                            <Skeleton round active paragraph={false} />
                                        )}
                                    </div>
                                    <Text className="italic">Not from Bank Statement</Text>
                                </Card>
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Sales Internal
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(internal)}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                >
                                    <Text className="italic">Cash to credit transfer</Text>
                                </Card>
                            </div>
                        ),
                    },
                    {
                        label: (
                            <div className="flex flex-row gap-0 items-center">
                                <Text className="uppercase">Accounts</Text>
                            </div>
                        ),
                        key: 'accounts',
                        children: (
                            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Accumulated Driver Cash Balance
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(driver_cash)}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                ></Card>
                                <Card
                                    className="h-fit"
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                Accumulated Driver Credit Balance
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(driver_credit)}`}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                ></Card>
                                <Card
                                    title={
                                        <div className="grid grid-cols-2 gap-2">
                                            <Text type="title-small" fontWeight="font-semibold">
                                                GCash Driver Cash Out Fund
                                            </Text>
                                            {!loading ? (
                                                <Text
                                                    type="title-small"
                                                    fontWeight="font-semibold"
                                                    className="text-right"
                                                >
                                                    {`PHP ${formatNumberToAmount(gcash_cashout_fund)}`}
                                                    {/* <img
                                                        id="g_cash"
                                                        onClick={addClick}
                                                        src="/images/add.png.png"
                                                        className="ml-2"
                                                    /> */}
                                                </Text>
                                            ) : (
                                                <Skeleton round active paragraph={false} />
                                            )}
                                        </div>
                                    }
                                ></Card>
                            </div>
                        ),
                    },
                ]}
            />
            <Modal title="Add funds" footer={false} {...addModal} onCancel={onFinishFailedForAddModal}>
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={onFinishForAddModal}
                    onFinishFailed={onFinishFailedForAddModal}
                >
                    <Form.Item label="Add Funds To" name="addValue" initialValue={addValue.type}>
                        <Input style={{ color: 'black' }} disabled />
                    </Form.Item>
                    <Form.Item label="Amount" name="amount" rules={[{ validator: validateNumber }]}>
                        <InputNumber type="number" />
                    </Form.Item>
                    <Form.Item>
                        <div className="flex justify-end">
                            <Button className="mr-2" onClick={onFinishFailedForAddModal}>
                                Cancel Transaction
                            </Button>
                            <Button disabled={isButtonDisabled} type="primary" htmlType="submit">
                                Next
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Confirm Transaction" footer={false} {...confirmationTranModal}>
                <div className="">
                    <Text type="title">Summary</Text>
                    <div className="flex justify-between rounded-lg	border border-[#B9CCDA] border-solid p-4">
                        <Text type="title">Add Fund To</Text>
                        <Text className="font-bold text-black">{addValue.type}</Text>
                    </div>
                    <div className="flex justify-between rounded-lg	border border-[#B9CCDA] border-solid p-4 border-t-0">
                        <Text type="title">Amount</Text>
                        <Text className="font-bold text-black">{addValue.amount}</Text>
                    </div>
                </div>
                <Form
                    name="confirm"
                    style={{ maxWidth: 600 }}
                    onFinish={onFinishForConfirmModal}
                    onFinishFailed={onFinishFailedForConfirmModal}
                    autoComplete="off"
                >
                    <Form.Item>
                        <div className="flex justify-end mt-6">
                            <Button className="mr-2" onClick={onFinishFailedForConfirmModal}>
                                Cancel Transaction
                            </Button>
                            <Button type="primary" htmlType="submit">
                                Confirm
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal closable={false} footer={false} {...confirmationModal}>
                <div className="text-center p-lg">
                    <EyeInvisibleOutlined style={{ fontSize: '35px' }} />
                    <Text type="title" className="mt-sm">
                        Sensitive Information
                    </Text>
                    <Text>This page contains sensitive information.</Text>
                    <div className="flex justify-center items-center gap-2 mt-md">
                        <Tooltip title="This will navigate you to the Homepage." placement="leftBottom">
                            <Button
                                onClick={() => {
                                    confirmationModal.close();
                                    navigate('/');
                                }}
                            >
                                Cancel
                            </Button>
                        </Tooltip>
                        <Button
                            type="primary"
                            ghost
                            onClick={() => {
                                confirmationModal.close();
                                setBlur(false);
                                setIsConfirmed(true);
                            }}
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default LedgerAccounts;
