import { Form, Input, Modal } from 'antd/lib';
import { CustomNotification, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createFleet } from 'services/message.service';

interface FleetModalProps {
    fleetModal: any;
    refreshList: any;
}

const CreateFleetModal: React.FC<FleetModalProps> = ({ fleetModal, refreshList }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const { request } = useApi({
        api: createFleet,
    });

    const onSave = () => {
        form.validateFields()
            .then(async values => {
                const body = {
                    ...values,
                    fleet_priority: parseFloat(values.fleet_priority),
                };
                try {
                    const result = await request({ body });
                    const { id, fleet_name } = result?.data?.data || {};
                    if (result.status === 'success') {
                        CustomNotification({
                            type: 'success',
                            message: 'Success',
                            description: (
                                <Text>
                                    Successfully created new fleet - <strong>{fleet_name}</strong>
                                </Text>
                            ),
                        });
                        navigate(`${id}`);
                        form.resetFields();
                    } else if (result.error) {
                        CustomNotification({
                            type: 'error',
                            message: 'Error',
                            description: `${result.error.message}`,
                        });
                    }
                } catch (error) {
                    CustomNotification({
                        type: 'error',
                        message: 'Error',
                        description: `Unable to add the new fleet. Please try again later.`,
                    });
                    form.resetFields();
                }
            })
            .catch(errorInfo => {
                CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: `Validation Error found. Please check all required fields.`,
                });
            });
    };

    return (
        <Modal
            {...fleetModal}
            title="Add New Fleet"
            okText="Save"
            onOk={onSave}
            okButtonProps={{ disabled: !isFormDirty || disableSave }}
            onCancel={() => {
                fleetModal.close();
                form.resetFields();
                setIsFormDirty(false);
            }}
        >
            <Form
                form={form}
                name="fleet_form"
                layout="vertical"
                onFieldsChange={(_, allFields) => {
                    setIsFormDirty(true);
                    let hasError = false;
                    allFields.forEach(field => {
                        if (field.errors && field.errors.length > 0) {
                            hasError = true;
                        }
                    });
                    setDisableSave(hasError);
                }}
            >
                <Form.Item
                    name="fleet_name"
                    label="Fleet Name"
                    rules={[
                        { required: true, message: 'Fleet name is required.' },
                        { max: 50, message: "Maximum of 50 character's only." },
                    ]}
                >
                    <Input placeholder="Enter fleet name" />
                </Form.Item>
                <Form.Item
                    name="fleet_description"
                    label="Description"
                    className="mb-0"
                    rules={[{ required: true, message: 'Description is required.' }]}
                >
                    <Input.TextArea rows={2} placeholder="Enter description" autoSize showCount maxLength={50} />
                </Form.Item>
                <Form.Item
                    name="fleet_priority"
                    label="Priority"
                    rules={[
                        { required: true, message: 'Priority is required.' },
                        {
                            type: 'number',
                            min: 0,
                            max: 9,
                            message: 'Must be between 0 and 9.',
                            transform: value => {
                                if (value !== undefined && value !== '') {
                                    const parsedValue = parseFloat(value);
                                    if (isNaN(parsedValue) || value !== parsedValue.toString()) {
                                        return NaN; // Return NaN for non-numeric input
                                    }
                                    return parsedValue;
                                }

                                return parseFloat(value);
                            },
                        },
                    ]}
                >
                    <Input placeholder="0-9" />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateFleetModal;
