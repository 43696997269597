import React from 'react';
import styles from './LoginLayout.module.scss';
import { Spin } from 'antd/lib';
import classnames from 'clsx';

export interface LoginLayoutProps {
    component: React.ReactNode;
    isLoading?: boolean;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ component, isLoading = false }) => {
    if (isLoading) {
        return (
            <div className="flex items-center justify-center w-screen h-screen">
                <Spin />
            </div>
        );
    }

    return (
        <div className={classnames(styles.LoginPage, 'w-screen h-screen flex items-center')}>
            <img src="/images/login-blob-top.png" alt="Blob Top" className={classnames(styles.BlobTop)} />

            <img src="/images/login-blob-bottom.png" alt="Blob Bottom" className={classnames(styles.BlobBottom)} />

            <div className="flex-1 h-screen">
                <div className={classnames(styles.LoginFields)}>
                    <img
                        src="/images/login-angkas-logo.png"
                        alt="Angkas Logo"
                        className={classnames(styles.AngkasLogo)}
                    />
                    <h1>Angkas Admin Portal</h1>
                    {component}
                </div>
            </div>
            <div className="flex-1 h-screen">
                <img
                    src="/images/login-illustration.png"
                    alt="Login Illustration"
                    className={classnames(styles.Illustration)}
                />
            </div>
        </div>
    );
};

export default LoginLayout;
