import { Form, Input, Modal } from 'antd/lib';
import { CustomNotification, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateFleet } from 'services/message.service';

interface FleetModalProps {
    fleet: any;
    fleetModal: any;
}

const UpdateFleetModal: React.FC<FleetModalProps> = ({ fleet, fleetModal }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const { request } = useApi({
        api: updateFleet,
    });

    const handleFormChange = (changedValues: any) => {
        setIsFormDirty(true);
    };

    const onSave = () => {
        form.validateFields()
            .then(async values => {
                try {
                    const result = await request({
                        id: fleet.id,
                        body: { ...values, fleet_priority: fleet.fleet_priority },
                    });
                    const { id } = result?.data?.data || {};
                    if (result.status === 'success') {
                        CustomNotification({
                            type: 'success',
                            message: 'Success',
                            description: <Text>Fleet details has been successfully saved.</Text>,
                        });
                        navigate(`${id}`);
                        form.resetFields();
                    } else if (result.error) {
                        CustomNotification({
                            type: 'error',
                            message: 'Error',
                            description: `${result.error.message}`,
                        });
                    }
                } catch (e) {
                    CustomNotification({
                        type: 'error',
                        message: 'Error',
                        description: `Unable to update the fleet. Please try again later.`,
                    });
                    form.resetFields();
                }
            })
            .catch(errorInfo => {
                CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: `Validation Error found. Please check all required fields.`,
                });
                form.resetFields();
            });
    };

    return (
        <Modal
            {...fleetModal}
            title="Update Fleet"
            okText="Save"
            onOk={onSave}
            okButtonProps={{ disabled: !isFormDirty || disableSave }}
            onCancel={() => {
                fleetModal.close();
                form.resetFields();
                setIsFormDirty(false);
            }}
        >
            <Form
                form={form}
                name="fleet_form"
                layout="vertical"
                initialValues={{
                    fleet_name: fleet.fleet_name,
                    fleet_description: fleet.fleet_description,
                }}
                onFieldsChange={(_, allFields) => {
                    setIsFormDirty(true);
                    let hasError = false;
                    allFields.forEach(field => {
                        if (field.errors && field.errors.length > 0) {
                            hasError = true;
                        }
                    });
                    setDisableSave(hasError);
                }}
            >
                <Form.Item
                    name="fleet_name"
                    label="Fleet Name"
                    rules={[
                        { required: true, message: 'Fleet name is required.' },
                        { max: 50, message: "Maximum of 50 character's only." },
                    ]}
                >
                    <Input placeholder="Enter fleet name" />
                </Form.Item>

                <Form.Item
                    name="fleet_description"
                    label="Description"
                    className="mb-0"
                    rules={[{ required: true, message: 'Description is required.' }]}
                >
                    <Input.TextArea rows={2} placeholder="Enter description" autoSize showCount maxLength={50} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default UpdateFleetModal;
