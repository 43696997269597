import { Text } from 'components/basic';
import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

interface DetailContentLayoutProps {
    goBackLink?: string;
    title?: string;
    children: ReactNode;
    header?: ReactNode;
    setContainer?: any;
    actionComponent?: any;
    goBackOnClick?: any;
    fallbackRoute?: string; // New prop for the fallback route
}

const DetailContentLayout: React.FC<DetailContentLayoutProps> = ({
    goBackLink,
    goBackOnClick,
    fallbackRoute = '/', // Default fallback to home
    title,
    children,
    header,
    setContainer,
    actionComponent
}) => {
    const navigate = useNavigate();

    const handleGoBack = () => {
        if (goBackOnClick) {
            goBackOnClick(); // Call the provided handler, if any.
        } else if (window.history.length > 2) { 
            navigate(-1); // Navigate to the previous page if possible.
        } else {
            navigate(fallbackRoute); // Navigate to the fallback route.
        }
    };

    return (
        <div className="flex justify-center items-center" ref={setContainer}>
            <div className="w-full">
                <div className="flex justify-between items-center">
                    <div
                        className="text-gray-500 text-sm cursor-pointer hover:text-blue"
                        onClick={handleGoBack}
                    >
                        ← Go Back
                    </div>
                    {actionComponent && (
                        <div className="flex flex-row items-center gap-2">
                            {actionComponent}
                        </div>
                    )}
                </div>
                {header && <div>{header}</div>}
                {title && (
                    <Text size="text-lg" fontWeight="font-semibold" className="mb-0">
                        {title}
                    </Text>
                )}
                {children}
            </div>
        </div>
    );
};

export default DetailContentLayout;
