import { Spin, Tag } from 'antd/lib';
import { Text } from 'components/basic';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { getDriverStatusHistory } from 'services/message.service';

const DriverStatusHistory = ({ id }: { id: any }) => {
    const { request, result, loading } = useApi({
        api: getDriverStatusHistory,
    });

    const { driver_status_history: statusHistory } = result?.data || {};

    useMount(() => {
        fetchDriverStatusHistory();
    });

    const fetchDriverStatusHistory = useCallback(async () => {
        try {
            await request({ id });
        } catch (error) {
            throw error;
        }
    }, [request, id]);

    const sortedStatusHistory = useMemo(() => {
        if (!statusHistory) return [];

        return statusHistory.sort((a: any, b: any) => {
            const dateA = moment(a.created_at);
            const dateB = moment(b.created_at);
            return dateB.diff(dateA);
        });
    }, [statusHistory]);

    const renderStatus = (status: any) => {
        return (
            <div className="ml-sm">
                {status === 'ACTIVE' && <Tag color="green">Active</Tag>}
                {status === 'DEACTIVATED' && <Tag color="red">Deactivated</Tag>}
                {status === 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                {status === 'BANNED' && <Tag>Banned</Tag>}
                {status === 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                {status === 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                {status === 'KTM' && <Tag>KTM</Tag>}
            </div>
        );
    };
    return (
        <div
            className={`bg-white rounded p-lg border border-dashed border-slate-200 ${
                sortedStatusHistory > 0 ? 'max-h-[340px]' : ''
            }`}
        >
            <Text color="text-gray" className="uppercase ">
                Biker Status History
            </Text>
            {!loading ? (
                <div className="flex-col justify-between items-center gap-2">
                    <div className={`mt-md flex flex-col gap-2 max-h-[270px] overflow-y-auto`}>
                        {sortedStatusHistory && sortedStatusHistory.length > 1 ? (
                            sortedStatusHistory?.map((item: any, index: number) => {
                                return (
                                    statusHistory[index + 1]?.status && (
                                        <div
                                            key={index}
                                            className="bg-white rounded px-md py-md border border-solid border-slate-200"
                                        >
                                            <Text className="font-semibold">
                                                {moment
                                                    .utc(item.created_at)
                                                    .add(8, 'hour')
                                                    .format('MMMM DD, YYYY hh:mm:ss A')}
                                            </Text>
                                            <div className="flex items-center">
                                                <span className="text-gray">Biker status changed from </span>
                                                {renderStatus(statusHistory[index + 1]?.status)}{' '}
                                                <span className="text-gray">to</span> {renderStatus(item.status)}
                                                <span className="text-gray break-all w-1/3">{`by ${
                                                    item.operator_name || item.operator_id || item.operator_email || '-'
                                                }.`}</span>
                                            </div>
                                        </div>
                                    )
                                );
                            })
                        ) : sortedStatusHistory.length === 1 ? (
                            <div className="bg-white rounded px-md py-md border border-solid border-slate-200">
                                <Text className="font-semibold">
                                    {moment
                                        .utc(sortedStatusHistory[0].created_at)
                                        .add(8, 'hour')
                                        .format('MMMM DD, YYYY hh:mm:ss A')}
                                </Text>
                                <div className="flex items-center">
                                    <span className="text-gray">{`Biker status changed to`}</span>
                                    {renderStatus(sortedStatusHistory[0].status)}
                                    <span className="text-gray break-all w-1/3">{`by ${
                                        sortedStatusHistory[0].operator_name ||
                                        sortedStatusHistory[0].operator_id ||
                                        sortedStatusHistory[0].operator_email ||
                                        '-'
                                    }.`}</span>
                                </div>
                            </div>
                        ) : (
                            <div className="h-full text-gray">No data available.</div>
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex justify-center items-center h-full">
                    <Spin />
                </div>
            )}
        </div>
    );
};

export default DriverStatusHistory;
