import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Divider, Modal, Select, Space, message } from 'antd/lib';
import Text from 'components/basic/Typography/Text';
import { Button, Input, Table } from 'components/basic';
const { Option } = Select;
import { EditOutlined, CloseOutlined } from '@ant-design/icons';
import useModal from 'hooks/useModal';
interface CustomerFilterProps {
    onFilterSubmit: (values: { field: string; keyword: any; account_status: string; service_zone: string }) => void;
    onFilterReset?: () => void;
    setPagination?: any;
    loading: boolean | undefined;
}

const CustomerFilter: React.FC<CustomerFilterProps> = ({ onFilterSubmit, onFilterReset, setPagination, loading }) => {
    const [selectedField, setSelectedField] = useState('phone_numbers');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedServiceZone, setSelectedServiceZone] = useState('');
    const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState([]);
    const [searchedPhoneNumber, setSearchedPhoneNumber] = useState('');
    const [phoneOptions, setPhoneOptions] = useState<any>([]);

    const phoneModal = useModal();
    const confirmationModal = useModal();

    const handleFieldChange = (value: string) => {
        setSelectedField(value);
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (selectedField === 'phone_number') {
            const inputValue = event.target.value?.replace(/\s/g, '');
            const isValidPhoneNumber = /^[\d+]*$/.test(inputValue);
            if (!isValidPhoneNumber) {
                // message.error('Please enter a valid phone number');
                return; // If the input value is not a number or a plus sign, return without setting the state
            } else if (inputValue.length > 13) {
                // if input is more than 13 chars
                return;
            }
            setSearchKeyword(inputValue);
        } else {
            setSearchKeyword(event.target.value);
        }
    };

    const handleFilterSubmit = () => {
        let kw = searchKeyword;
        const cleanPhoneNumbers = selectedPhoneNumbers.map((number: string) => number.replace('+', ''));
        if (selectedField === 'public_id') {
            kw = kw.replace(/[a-zA-Z]/g, '');
        }
        if (onFilterSubmit) {
            onFilterSubmit({
                field: selectedField === 'phone_number' ? 'phone_numbers' : selectedField,
                keyword: selectedField === 'phone_numbers' ? cleanPhoneNumbers : kw,
                account_status: selectedStatus,
                service_zone: selectedServiceZone,
            });
            phoneModal.close();
        }
    };

    const handleFilterReset = () => {
        setSelectedField('phone_numbers');
        setSelectedStatus('');
        setSearchKeyword('');
        setSelectedServiceZone('');
        setSelectedPhoneNumbers([]);
        setPhoneOptions([]);
        onFilterSubmit({
            field: 'phone_numbers',
            account_status: '',
            keyword: '',
            service_zone: '',
        });
    };

    const searchPhoneNumber = useCallback(() => {
        // Convert searchTerm to lowercase for case-insensitive search
        const searchTermLowerCase = searchedPhoneNumber.toLowerCase().trim();

        // Filter phoneNumbers array based on the searchTerm
        const filteredNumbers = selectedPhoneNumbers.filter((num: string) => {
            const numberLowerCase = num.toLowerCase();
            return numberLowerCase.includes(searchTermLowerCase);
        });

        return filteredNumbers;
    }, [selectedPhoneNumbers, searchedPhoneNumber]);

    const phoneData = useMemo(() => {
        if (searchedPhoneNumber && searchedPhoneNumber !== '') {
            const res = searchPhoneNumber();

            return res.map(item => {
                return {
                    phone_numbers: item,
                    id: item,
                };
            });
        }
        return selectedPhoneNumbers.map(item => {
            return {
                phone_numbers: item,
                id: item,
                key: item,
            };
        });
    }, [selectedPhoneNumbers, searchedPhoneNumber, searchPhoneNumber]);

    const onRemovePhoneNumber = (itemToRemove: any) => {
        const updatedPhoneNumbers = selectedPhoneNumbers.filter((item: string) => item !== itemToRemove.phone_numbers);
        setSelectedPhoneNumbers(updatedPhoneNumbers);
        showMessage(
            <span>
                <strong>{itemToRemove.id}</strong> successfully removed.
            </span>,
            'info',
        );
    };

    const handleTagClose = useCallback(
        (removedValue: any) => {
            const newSelectedValues = selectedPhoneNumbers.filter(value => value !== removedValue);
            setSelectedPhoneNumbers(newSelectedValues);
        },
        [selectedPhoneNumbers],
    );

    const tagRender = useCallback(
        (props: any) => {
            const { value } = props;
            const isValidPhoneNumber = /^[\d+]*$/.test(value);
            const tagStyle =
                !isValidPhoneNumber || value.length > 13 || value.length < 11
                    ? { backgroundColor: '#ff4d4f', color: 'white' }
                    : {};

            return (
                <div
                    className={`ant-select-selection-item ${
                        !isValidPhoneNumber || value.length > 13 || value.length < 11 ? 'invalid-tag' : ''
                    }`}
                    style={tagStyle}
                >
                    {value}
                    <span
                        className="ant-select-selection-item-remove ml-sm"
                        onClick={() => {
                            handleTagClose(value);
                        }}
                    >
                        <CloseOutlined />
                    </span>
                </div>
            );
        },
        [handleTagClose],
    );

    const showMessage = (msg: any, type = 'error') => {
        message.destroy(); // Destroy any existing messages
        if (type === 'error') {
            message.error(msg); // Display the new message
        } else {
            message.info(msg); // Display the new message
        }
    };

    const phone_numbers = useMemo(() => {
        return phoneOptions.map((d: any) => ({
            value: d,
            label: d,
        }));
    }, [phoneOptions]);

    return (
        <div className="flex items-start gap-2">
            <div className="flex items-start gap-2">
                <div className="block w-full">
                    <Text type="label">Search by name, phone number, or Customer ID</Text>
                    <div className="flex gap-1 w-96">
                        <Select value={selectedField} className="w-1/2" onChange={handleFieldChange}>
                            <Option value="phone_numbers">Phone Numbers</Option>
                            {/* <Option value="phone_number">Phone Number</Option> */}
                            <Option value="first_name">First Name</Option>
                            <Option value="last_name">Last Name</Option>
                            <Option value="email">Email</Option>
                            <Option value="public_id">Customer ID</Option>
                            <Option value="id">Internal ID</Option>
                            <Option value="jalopy_id">MH User ID</Option>
                            <Option value="jalopy_firebase_id">MH Firebase ID</Option>
                        </Select>

                        {selectedField === 'phone_numbers' ? (
                            <Select
                                mode="tags"
                                className="w-96"
                                placeholder="Enter single/multiple phone numbers..."
                                tokenSeparators={[',', ' ', '\n', '\t']}
                                allowClear
                                onClear={() => setPhoneOptions([])}
                                maxTagCount="responsive"
                                maxTagTextLength={11}
                                notFoundContent={
                                    <div>The phone number that has been copied should be inserted here.</div>
                                }
                                tagRender={tagRender}
                                options={phone_numbers}
                                value={selectedPhoneNumbers}
                                onChange={value => {
                                    let validValues: any = [];
                                    let truncatedValues = value.slice(0, 10000);

                                    if (value.length <= 10000) {
                                        setSelectedPhoneNumbers(truncatedValues);
                                    } else {
                                        setSelectedPhoneNumbers(truncatedValues);
                                        showMessage('Maximum selection limit (10,000) reached!');
                                    }
                                    value.forEach((v: any) => {
                                        const formattedValue = v.replace(/\r/g, '');
                                        const isValidPhoneNumber = /^[\d+]*$/.test(formattedValue);
                                        const lengthValid =
                                            formattedValue?.length >= 11 && formattedValue?.length <= 13;
                                        if (!isValidPhoneNumber || !lengthValid) {
                                            showMessage('Removing invalid phone numbers..');
                                            setSelectedPhoneNumbers(prev => prev.filter(val => val !== formattedValue));
                                        } else if (!phoneOptions.includes(formattedValue)) {
                                            validValues.push(formattedValue);
                                        }
                                    });

                                    setPhoneOptions((prevOptions: any) => [...prevOptions, ...validValues]);
                                }}
                                onSearch={value => {
                                    const isValidPhoneNumber = /^[\d+]*$/.test(value);
                                    if (!isValidPhoneNumber) {
                                        showMessage('Please enter a valid phone number');

                                        return; // If the input value is not a number or a plus sign, return without setting the state
                                    } else if (value.length > 13) {
                                        // if input is more than 13 chars
                                        showMessage(
                                            'Input number is greater than (13) number of a valid phone number.',
                                        );
                                        return;
                                    }
                                }}
                                showSearch
                                dropdownRender={menu => {
                                    return (
                                        <>
                                            {menu}
                                            {selectedPhoneNumbers.length > 0 && (
                                                <>
                                                    <Divider style={{ margin: '8px 0' }} />
                                                    <Space style={{ padding: '0 8px 4px' }}>
                                                        <Button
                                                            type="text"
                                                            icon={<EditOutlined />}
                                                            onClick={() => phoneModal.show()}
                                                        >
                                                            Manage Phone Numbers
                                                        </Button>
                                                    </Space>
                                                </>
                                            )}
                                        </>
                                    );
                                }}
                            />
                        ) : (
                            <Input
                                className="rounded-full p-lg"
                                value={searchKeyword}
                                onChange={handleKeywordChange}
                                placeholder="Enter search keyword..."
                                onPressEnter={handleFilterSubmit}
                            />
                        )}
                    </div>
                </div>
                <div className="block">
                    <Text type="label">Select Status</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'ACTIVE', label: 'Active' },
                            { value: 'DEACTIVATED', label: 'Deactivated' },
                        ]}
                    />
                </div>
                <div className="block">
                    <Text type="label">Service Zone</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedServiceZone}
                        onChange={setSelectedServiceZone}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'MNL', label: 'MNL' },
                            { value: 'BCD', label: 'BCD' },
                            { value: 'BTG', label: 'BTG' },
                            { value: 'CDO', label: 'CDO' },
                            { value: 'CEB', label: 'CEB' },
                            { value: 'DVO', label: 'DVO' },
                            { value: 'ILO', label: 'ILO' },
                            { value: 'PPG', label: 'PPG' },
                        ]}
                    />
                </div>
            </div>
            <div className="flex items-center gap-1 mt-lg">
                <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
            <Modal
                {...phoneModal}
                style={{ top: 30 }}
                title="Search Bulk Entries"
                footer={
                    <div className="flex justify-between">
                        <Button
                            type="ghost"
                            onClick={() => confirmationModal.show()}
                            disabled={selectedPhoneNumbers.length === 0}
                        >
                            Clear All
                        </Button>
                        <Button type="primary" onClick={handleFilterSubmit}>
                            Confirm Search
                        </Button>
                    </div>
                }
            >
                <Input
                    className="rounded-full p-lg"
                    placeholder="Search phone number..."
                    onChange={e => {
                        const value = e.target.value;
                        setSearchedPhoneNumber(value);
                    }}
                />
                <div className="mt-xs">
                    <Alert message={<div>You can only search for a single phone number.</div>} type="info" showIcon />
                </div>
                <Table
                    className="mt-md"
                    dataSource={phoneData}
                    columns={[
                        {
                            key: 'phone_numbers',
                            title: 'Phone number',
                            dataIndex: 'phone_numbers',
                        },
                        {
                            key: 'action',
                            title: 'Action',
                            align: 'right',
                            render: item => (
                                <a
                                    className="text-red-500 hover:text-red-400"
                                    onClick={() => onRemovePhoneNumber(item)}
                                >
                                    Remove
                                </a>
                            ),
                        },
                    ]}
                    pagination={{
                        showTotal: (total: any) => `${total?.toLocaleString()} items`,
                        defaultCurrent: 1,
                        defaultPageSize: 10,
                        size: 'small',
                        hideOnSinglePage: true,
                        showQuickJumper: true,
                        showSizeChanger: false,
                    }}
                />
                {/* <div className="flex justify-between mt-md">
                    <Tooltip title="This will override the selected phone numbers.">
                        <Upload
                            name="file"
                            accept=".csv"
                            multiple={false}
                            beforeUpload={() => false} // To prevent auto upload
                            onChange={info => handleUpload(info)}
                        >
                            <Button icon={<UploadOutlined />}>Upload CSV</Button>
                        </Upload>
                    </Tooltip> */}

                {/* </div> */}
            </Modal>
            <Modal
                {...confirmationModal}
                title="Are you sure?"
                onOk={() => {
                    setSelectedPhoneNumbers([]);
                    confirmationModal.close();
                }}
                onCancel={() => confirmationModal.close()}
                okText="Yes"
            >
                This will clear all the phone numbers you selected.
            </Modal>
        </div>
    );
};

export default CustomerFilter;
