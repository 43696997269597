import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

export const LogoutButton = () => {
    const { logout, user } = useAuth0();

    const handleLogout = () => {
        localStorage.removeItem('userToken');
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    return (
        <button
            className="bg-inherit border-transparent w-full text-left cursor pointer"
            onClick={handleLogout}
        >
            Log Out
        </button>
    );
};

export default LogoutButton;
