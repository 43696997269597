import { Form, Select, DatePicker } from 'antd/lib';
import { Button, Text } from 'components/basic';
import moment from 'moment';
import React, { useState } from 'react';
const { Option } = Select;
const { RangePicker } = DatePicker;

interface DriverTripHistoryFilterProps {
    onFilterSubmit: (values: { status: string; trip_date: string; date_range: string; payment_type: string }) => void;
    loading: boolean | undefined;
}

const DriverTripHistoryFilter: React.FC<DriverTripHistoryFilterProps> = ({ onFilterSubmit, loading }) => {
    const [selectedDate, setSelectedDate] = useState('');
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        onFilterSubmit(values);
    };

    const onReset = () => {
        form.resetFields();
        setSelectedDate('');
        onFilterSubmit(form.getFieldsValue());
    };

    const disabledDate = (current: any) => current && current > moment().endOf('day');

    return (
        <div className="flex items-end gap-2">
            <Form
                className="flex flex-1 items-center gap-1"
                form={form}
                onFinish={onFinish}
                layout="inline"
                initialValues={{
                    status: 'all',
                    trip_date: '',
                    date_range: '',
                    payment_type: '',
                }}
                onValuesChange={(changedValues, values) => {
                    setSelectedDate(values.trip_date);
                }}
            >
                <div>
                    <Text type="label">Filter by trip status</Text>
                    <Form.Item name="status" className="w-36">
                        <Select>
                            <Option value="all">All</Option>
                            {/* <Option value="finding_driver">Finding Driver</Option> */}
                            <Option value="driver_otw_pickup">Driver OTW to Pickup</Option>
                            <Option value="driver_arrived_pickup">Driver Arrived at Pickup</Option>
                            <Option value="driver_otw_destination">Driver OTW to Destination</Option>
                            <Option value="driver_arrived_destination">Driver Arrived at Destination</Option>
                            <Option value="complete">Complete</Option>
                            <Option value="driver_canceled">Driver Cancelled</Option>
                            <Option value="customer_canceled">Customer Cancelled</Option>
                            <Option value="operator_canceled">Operator Cancelled</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div>
                    <Text type="label">Filter by date</Text>
                    <Form.Item name="trip_date" className="w-36">
                        <Select>
                            <Option value="">All</Option>
                            <Option value="TODAY">Today</Option>
                            <Option value="PAST_WEEK">Past 7 days</Option>
                            <Option value="CUSTOM">Custom Date Range</Option>
                        </Select>
                    </Form.Item>
                </div>
                {selectedDate === 'CUSTOM' && (
                    <div>
                        <Text type="label">Select start date and end date</Text>
                        <Form.Item name="date_range" dependencies={['trip_date']}>
                            <RangePicker disabledDate={disabledDate} />
                        </Form.Item>
                    </div>
                )}
                <div>
                    <Text type="label">Filter by Payment</Text>
                    <Form.Item name="payment_type" className="w-36">
                        <Select>
                            <Option value="">All</Option>
                            <Option value="cash">Cash</Option>
                            <Option value="card">Cashless</Option>
                        </Select>
                    </Form.Item>
                </div>
                <div className="flex items-center self-end">
                    <Form.Item label="">
                        <Button type="primary" ghost loading={loading} disabled={loading} htmlType="submit">
                            Apply Filter
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={loading} onClick={onReset}>
                            Clear Filters
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default DriverTripHistoryFilter;
