import { Button, Dropdown, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { ExportOutlined, DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import { getCustomerList } from 'services/message.service';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import CustomerFilter from '../Customers/CustomerFilter.tsx/CustomerFilter';

const VipCustomersPage = () => {
    const managerHubUrl = process.env.REACT_APP_MANAGER_HUB_URL;
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });

    const { request, loading } = useApi({
        api: getCustomerList,
    });

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: 'phone_number',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
        account_status: '',
        service_zone: '',
    });

    useMount(() => {
        fetchCustomers(requestState);
    });

    const fetchCustomers = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                if (d.status == 'success') {
                    let customers = d.data.customers.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    setData(customers);
                    let pagination = d.data.pagination;
                    let p = {
                        current: pagination.page,
                        defaultCurrent: 1,
                        pageSize: pagination.page_size,
                        total: pagination.total_count,
                    };
                    setPagination(p);
                } else {
                    // TODO: error screen
                }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchCustomers(requestState || {});
        },
        [fetchCustomers, modifyFilters],
    );

    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            search_by: filter.field,
            search_key: filter.keyword,
            account_status: filter.account_status,
            service_zone: filter.service_zone,
        });
        await fetchCustomers({
            ...requestState,
            page: 1,
            search_by: filter.field,
            search_key: filter.keyword,
            account_status: filter.account_status,
            service_zone: filter.service_zone,
        });
    };

    const columns = [
        {
            title: 'Customer ID',
            key: 'customer_id',
            render: (user: any) => (
                <div className="flex items-center">
                    <a href={`/vip-customers/${user.id}`} target="_blank">
                        {user.public_id}
                    </a>
                </div>
            ),
        },
        {
            title: 'First Name',
            key: 'first_name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.first_name })}>{user.first_name || '-'}</Text>
            ),
        },
        {
            title: 'Last Name',
            key: 'last_name',
            render: (user: any) => (
                <Text className={classnames({ 'text-gray-500': !user.last_name })}>{user.last_name || '-'}</Text>
            ),
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone_number',
            key: 'phone_number',
        },
        {
            title: 'Customer Internal ID',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.id}</Text>
                </div>
            ),
        },
        // {
        //     title: '',
        //     render: (user: any) => ColumnActions(user),
        // },
    ];

    const ColumnActions = (user: any) => {
        return (
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'View',
                            key: '1',
                            onClick: () => {
                                navigate(`/customers/${user.id}/overview`);
                            },
                        },
                        {
                            label: 'View in Manager Hub',
                            key: '2',
                            disabled: user.jalopy_id == 0,
                            onClick: () => {
                                window.open(`${managerHubUrl}/customer/detail/${user.jalopy_id}`, '_blank');
                            },
                        },
                    ],
                }}
            >
                <Button>
                    <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    const csvData = [
        ['customer_id', 'first_name', 'last_name', 'customer_internal_id'],
        ...data.map(({ public_id, first_name, last_name, id }) => [public_id, first_name, last_name, id]),
    ];

    const currentDateTime = moment().format('YYYY-MM-DD-HHmmss');

    return (
        <ListLayout
            title="VIP Customers"
            searchComponent={
                <CustomerFilter onFilterSubmit={onFilterSubmit} setPagination={setPagination} loading={loading} />
            }
            actionComponent={
                <Button type="primary">
                    <CSVLink className="downloadbtn" filename={`vip_customers_${currentDateTime}.csv`} data={csvData}>
                        <ExportOutlined /> Export to CSV
                    </CSVLink>
                </Button>
            }
        >
            <Table
                // loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                onChange={onTableChange}
            />
        </ListLayout>
    );
};

export default VipCustomersPage;
