import React from 'react';
import FareConfigServiceType, { formatFareConfigErrorMessage } from './FareConfigServiceType';
import { Modal } from 'antd/lib';
import { createFareConfig } from 'services/message.service';
import useApi from 'hooks/useApi';
import { CustomNotification } from 'components/basic';

const CreateFareConfigModal = (props: any) => {
    const { request, loading } = useApi({
        api: createFareConfig,
    });

    const handleFareConfigServiceTypeFinish = async (body: any) => {
        const result = await request({ body });
        if (!result.error) {
            if (props.refreshList) {
                await props.refreshList();
            }
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: 'Successfully Saved',
            });
            props.close();
        } else if (result.error.customMessage) {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: formatFareConfigErrorMessage(result.error.customMessage),
            });
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: result.error.message,
            });
        }
    };

    return (
        <Modal
            title="Create New Fare Config"
            open={props.isModalOpen}
            okText="Submit"
            onCancel={props.onCancel}
            confirmLoading={loading}
            destroyOnClose
            width={1000}
            footer={false}
        >
            <FareConfigServiceType
                onCreate
                initialVersion={props.initialVersion}
                onCancel={props.onCancel}
                handleCreate={handleFareConfigServiceTypeFinish}
                inModal
            />
        </Modal>
    );
};

export default CreateFareConfigModal;
