import React, { useCallback, useContext, useState } from 'react';
import { Form, Input, Button, Tag } from 'antd/lib';
import { Table, CustomNotification } from 'components';
import useMount from 'hooks/useMount';
import useApi from 'hooks/useApi';
import { getCustomerTransactionList, adjustRefund } from 'services/message.service';
import useFilter from 'hooks/useFilter';
import CustomerTransactionFilter from './CustomerTransactionFilter';
import { Dropdown, Text } from 'components/basic';
import { DownOutlined } from '@ant-design/icons';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
import useModal from 'hooks/useModal';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';

const CustomerTransaction = () => {
    const [data, setData] = useState([]);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const navigate = useNavigate();
    const userRole = useContext(AuthUserContext);
    const refundModal = useModal();

    const { request: requestRefund, loading: adjusting } = useApi({
        api: adjustRefund,
    });

    const ColumnActions = useCallback(
        (user: any) => {
            const handleRefund = async ({ id }: { id: string }) => {
                try {
                    form.validateFields().then(async values => {
                        const { reason } = values || {};
                        const res = await requestRefund({
                            body: {
                                trip_request_id: id,
                                reason: reason,
                            },
                        });
                        if (res.error === null) {
                            CustomNotification({
                                type: 'success',
                                message: 'Status Changed',
                                description: `Status changed to 'Refund Requested' successfully. Refund process initiated for the transaction.`,
                            });
                        } else {
                            CustomNotification({
                                type: 'error',
                                message: 'Error',
                                description: res.error?.message,
                            });
                        }
                        refundModal.close();
                        form.resetFields();
                        setIsFormDirty(false);
                    });
                } catch (error) {
                    throw error;
                }
            };

            const hasActionAccess = (page: string) => {
                return (
                    getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
                    getPermissions(userRole, page)?.includes(Accessibility.EDIT)
                );
            };
            return user.status == 'CAPTURED' && hasActionAccess(PagesEnum.CUSTOMER_TRANSACTIONS) ? (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View Trips',
                                key: '1',
                                onClick: () => {
                                    navigate(`/trips/${user.trip_request_id}`);
                                },
                            },
                            {
                                label: 'Refund',
                                key: '2',
                                onClick: () => {
                                    refundModal.show({
                                        title: 'Refund Transaction',
                                        okText: 'Confirm',
                                        onOk: () => handleRefund({ id: user.trip_request_id }),
                                        children: (
                                            <div>
                                                <Form
                                                    name="adjustCreditBalanceForm"
                                                    layout="vertical"
                                                    form={form}
                                                    onFieldsChange={(_, allFields) => {
                                                        setIsFormDirty(true);
                                                        let hasError = false;
                                                        allFields.forEach(field => {
                                                            if (
                                                                (field.errors && field.errors.length > 0) ||
                                                                !field.touched
                                                            ) {
                                                                hasError = true;
                                                            }
                                                        });
                                                        setDisableSave(hasError);
                                                    }}
                                                >
                                                    <h4>Transaction Summary</h4>
                                                    <div className="border mb-3 rounded-xl border-[#B9CCDA] border-solid">
                                                        <div className="flex justify-between items-center p-2">
                                                            <span className="text-sm">Customer ID</span>
                                                            <h5 className="mb-0">{user.customer_id}</h5>
                                                        </div>
                                                        <hr color="#B9CCDA" />
                                                        <div className="flex justify-between items-center p-2">
                                                            <span className="text-sm">Final Fare</span>
                                                            <h5 className="mb-0">PHP {user.amount}</h5>
                                                        </div>
                                                    </div>
                                                    <Form.Item
                                                        label="Reason"
                                                        name="reason"
                                                        rules={[{ required: true, message: 'Reason is required.' }]}
                                                    >
                                                        <Input.TextArea
                                                            rows={2}
                                                            placeholder="Enter reason."
                                                            autoSize
                                                            showCount
                                                            maxLength={240}
                                                        />
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        ),
                                    });
                                },
                            },
                        ],
                    }}
                >
                    <Button type="ghost">
                        <DownOutlined />
                    </Button>
                </Dropdown>
            ) : (
                <Dropdown
                    menu={{
                        items: [
                            {
                                label: 'View Trips',
                                key: '1',
                                onClick: () => {
                                    navigate(`/trips/${user.trip_request_id}`);
                                },
                            },
                        ],
                    }}
                >
                    <Button type="ghost">
                        <DownOutlined />
                    </Button>
                </Dropdown>
            );
        },
        [form, navigate, requestRefund, refundModal, userRole],
    );

    const { request, loading } = useApi({
        api: getCustomerTransactionList,
    });

    useMount(() => {
        fetchCustomerTransactionList(requestState);
    });

    const fetchCustomerTransactionList = useCallback(
        async (requestState: {}) => {
            try {
                request(requestState)
                    .then(result => {
                        let d = result;
                        if (d?.error === null) {
                            let customerTransactions = d?.data?.payments.map((c: any) => {
                                return {
                                    ...c,
                                    key: c.id,
                                };
                            });
                            setData(customerTransactions);
                            let pagination = d.data.pagination;
                            let p = {
                                ...pagination,
                                current: pagination.page,
                                defaultCurrent: 1,
                                pageSize: pagination.page_size,
                                total: pagination.total_count,
                            };
                            setPagination(p);
                        } else {
                            CustomNotification({
                                type: 'error',
                                message: 'Error',
                                description: result.error?.message,
                            });
                            setData([]);
                            setPagination({});
                        }
                    })
                    .catch((error: any) => {
                        CustomNotification({
                            type: 'error',
                            message: 'Error',
                            description: error,
                        });
                    });
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        status: '',
        // mobile_number:'',
        customer_id: '',
        // reference_id:'',
        trip_request_id: '',
        created_at_gte: '',
        created_at_lte: '',
    });

    const columns = [
        {
            title: 'Trip ID',
            key: 'trip_request_id',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.trip_request_id}</Text>
                </div>
            ),
        },
        {
            title: 'Customer ID',
            key: 'customer_id',
            render: (user: any) => (
                <a href={`/customers/${user.customer_id}/overview`} target="_blank">
                    {user.customer_id}
                </a>
            ),
        },
        {
            title: 'Final Fare',
            render: (user: any) => <div className="w-full font-semibold">PHP {parseFloat(user.amount).toFixed(2)}</div>,
        },
        {
            title: 'GCash Reference No.',
            key: 'reference_id',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text type="uuid">{user.reference_id}</Text>
                </div>
            ),
        },
        {
            title: 'Transaction Date',
            render: (user: any) => (
                <span className="cursor-pointer">
                    {moment.utc(user.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A')}
                </span>
            ),
        },
        {
            title: 'Status',
            render: (user: any) => (
                <div className="flex items-start flex-col">
                    <div className="">
                        {user.status == 'CAPTURED' && <Tag color="green">Success</Tag>}
                        {user.status == 'VOIDED' && <Tag color="red">Failed</Tag>}
                        {user.status == 'CAPTURE_FAILED' && <Tag color="red">Capture Failed</Tag>}
                        {user.status == 'AUTO_VOIDED' && <Tag color="red">Failed</Tag>}
                        {user.status == 'REFUND_REQUESTED' && <Tag color="orange">Refund Requested</Tag>}
                        {user.status == 'AUTHORIZED' && <Tag color="green">Authorized</Tag>}
                        {user.status == 'REFUNDED' && <Tag color="black">Refunded</Tag>}
                        {user.status == 'AWAITAUTH' && <Tag color="orange">Pending</Tag>}
                        {user.status == 'REFUND_FAILED' && <Tag color="red">Refund Failed</Tag>}
                    </div>
                </div>
            ),
        },
        {
            title: '',
            render: (user: any) => ColumnActions(user),
        },
    ];

    const onTableChange = useCallback(
        async (pagination: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchCustomerTransactionList(requestState || {});
        },
        [fetchCustomerTransactionList, modifyFilters],
    );

    const onFilterSubmit = async (filter: any) => {
        modifyFilters({
            customer_id: filter.field === 'customer_id' ? filter.keyword : '',
            trip_request_id: filter.field === 'trip_request_id' ? filter.keyword : '',
            status: filter.status,
            created_at_gte: filter.created_at_gte,
            created_at_lte: filter.created_at_lte,
        });
        await fetchCustomerTransactionList({
            ...requestState,
            page: 1,
            customer_id: filter.field === 'customer_id' ? filter.keyword : '',
            trip_request_id: filter.field === 'trip_request_id' ? filter.keyword : '',
            status: filter.status,
            created_at_gte: filter.created_at_gte,
            created_at_lte: filter.created_at_lte,
        });
    };
    return (
        <ListLayout
            title="Customer Bind and Pay Transactions"
            searchComponent={<CustomerTransactionFilter onFilterSubmit={onFilterSubmit} loading={loading} />}
        >
            <Table
                loading={loading}
                columns={columns}
                dataSource={data}
                pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    showTotal: (total: any) => `${total?.toLocaleString()} items`,
                }}
                scroll={{ x: 1000 }} // fix horizontal
                onChange={onTableChange}
            />
            <Modal
                width={450}
                {...refundModal}
                okButtonProps={{ disabled: !isFormDirty || disableSave, loading: adjusting }}
                onCancel={() => {
                    refundModal.close();
                    form.resetFields();
                    setIsFormDirty(false);
                }}
            />
        </ListLayout>
    );
};

export default CustomerTransaction;
