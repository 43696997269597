import { Input } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useCallback, useState } from 'react';
import { getTrip } from 'services/message.service';
import { notification } from 'antd/lib';
import { SearchOutlined } from '@ant-design/icons';

const TripsPage = () => {
    const [tripId, setTripId] = useState('');

    const { request: getTripRequest, loading: getTripLoading } = useApi({
        api: getTrip,
    });

    const fetchTrip = useCallback(
        async (id: string) => {
            try {
                getTripRequest({ id }).then(res => {
                    if (!res.error) {
                        window.open(`/trips/${id}`, '_blank');
                        setTripId('');
                    } else {
                        notification.error({
                            message: 'Error',
                            description: 'Invalid Trip ID',
                            duration: 3,
                        });
                    }
                });
            } catch (error) {
                throw error;
            }
        },
        [getTripRequest],
    );

    return (
        <div className="flex flex-col">
            <div className="text-sm p-16 text-center bg-slate-50 rounded">
                <span className="mt-4 block text-gray-600">
                    To look for a trip, go to a <a href="/drivers">Driver</a> or <a href="/customers">Customer</a>'s
                    Profile and view their Trip History.
                </span>

                <span className="mt-2 block text-gray-500">
                    Alternatively, enter a Trip ID in the search bar below.
                </span>

                <div className="w-96 inline-block">
                    <Input
                        prefix={<SearchOutlined />}
                        className="rounded-full p-lg mt-4"
                        placeholder="Enter a Trip ID here to quickly view a trip..."
                        value={tripId}
                        allowClear
                        disabled={getTripLoading}
                        onChange={(e: any) => {
                            setTripId(e.target.value);
                        }}
                        onPressEnter={() => {
                            fetchTrip(tripId);
                            // window.open(`/trips/${tripId}`, '_blank');
                            // setTripId('');
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default TripsPage;
