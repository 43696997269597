import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Table } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useFilter from 'hooks/useFilter';
import useApi from 'hooks/useApi';
import { createDriver } from 'services/message.service';
import { downloadCSVFile } from 'services/file.service';
import papa from 'papaparse';
import { json2csv } from 'json-2-csv';
import { useNavigate } from 'react-router-dom';
import { Alert, Tag, message, notification } from 'antd/lib';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const UploadActivateDrivers = () => {
    const [showDownloadBtn, setShowDownloadBtn] = useState(false);
    const [tableData, setTableData]: any = useState([]);
    const [erroNumber, setErrorNumber] = useState(0);
    const [csv, setCsv] = useState('');
    const [successNumber, setSuccessNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const defaultBatchSize = 7;
    const sleepPerBatchMS = 2000;
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const { requestState } = useFilter({
        search_by: '',
        search_key: '',
        driver_id: '',
        user_id: '',
        limit: 10,
    });

    const { request: activateDriversRequest } = useApi({
        api: createDriver,
    });

    const uploadCsv = (csvResult: []) => {
        uploadCsvData(csvResult);
    };

    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

    const sliceIntoChunks = (arr: any[], chunkSize: number) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    };

    const uploadCsvData = useCallback(
        async (csvResult: []) => {
            setLoading(true);
            notification.info({
                key: 'progress',
                message: 'Uploading',
                description: '0% completed',
            });

            let chunks = sliceIntoChunks(csvResult, defaultBatchSize);
            const result: any[] = [];
            let validationError = false;
            const failedRecords: any[] = [];
            try {
                for (let i = 0; i < chunks.length; i++) {
                    await Promise.all(
                        chunks[i].map(async (res: any) => {
                            const apiRes = await activateDriversRequest({ token, res });
                            if (apiRes.error) {
                                if (apiRes.error.errorCode === 'VALIDATION_ERROR') {
                                    validationError = true;
                                } else {
                                    let newarray = res?.service_types?.map((element: any, index: any) => (
                                        <p key={index}>{element}</p>
                                    ));
                                    failedRecords.push({
                                        phone_number: res?.phone_number,
                                        status: res?.status,
                                        imei: res?.imei,
                                        vehicle_number: res?.vehicle_number,
                                        plate_number: res?.plate_number,
                                        chassis_number: res?.chassis_number,
                                        engine_number: res?.engine_number,
                                        piston_displacement: Number(res?.piston_displacement),
                                        transmission_type: res?.transmission_type,
                                        year: Number(res?.year),
                                        tnv_status: res?.tnv_status,
                                        vehicle_make: res?.vehicle_make,
                                        series: res?.series,
                                        model: res?.model,
                                        license_number: res?.license_number,
                                        license_expired_date: res?.license_expired_date,
                                        first_name: res?.first_name,
                                        middle_name: res?.middle_name,
                                        last_name: res?.last_name,
                                        service_zone: res?.service_zone,
                                        service_types: newarray,
                                        pa_start_date: res?.pa_start_date,
                                        pa_expiry_date: res?.pa_expiry_date,
                                        registration_expiry_date: res?.registration_expiry_date,
                                        failed_response: `${apiRes.error.message}` + ',' + `status : ${apiRes.status}`,
                                    });
                                }
                            } else {
                                result.push(apiRes);
                            }
                        }),
                    );
                    // wait for 1s
                    await delay(sleepPerBatchMS);
                    notification.info({
                        key: 'progress',
                        message: 'Uploading',
                        description:
                            Number((i + 1) / chunks.length).toLocaleString(undefined, {
                                style: 'percent',
                                minimumFractionDigits: 2,
                            }) + ' completed',
                    });
                }
                notification.destroy();
                if (validationError) {
                    message.error('Please upload a CSV file with a valid template.');
                } else if (failedRecords.length == 0) {
                    notification.success({
                        message: 'Success',
                        description: 'All rows are successfully uploaded',
                    });

                    navigate('/drivers');
                    return;
                } else if (result.length > 0) {
                    notification.warning({
                        message: 'Error',
                        description: 'Some rows encountered error',
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Error for all rows',
                        duration: 3,
                    });
                }

                setErrorNumber(failedRecords.length);
                setShowDownloadBtn(true);
                setSuccessNumber(result.length);
                setTableData(failedRecords);

                const csvData = await json2csv(failedRecords);
                setCsv(csvData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [token, activateDriversRequest, navigate],
    );

    const downloadFailedData = () => {
        const csvData = String(csv);
        downloadCSVFile(csvData, 'failed_activate_driver');
    };

    const downloadTemplate = () => {
        let tempData = `phone_number,status,imei,vehicle_number,plate_number,chassis_number,engine_number,piston_displacement,transmission_type,year,tnv_status,vehicle_make,series,model,license_number,license_expired_date,first_name,middle_name,last_name,service_zone,service_types,fleet,pa_start_date,pa_expiry_date,registration_expiry_date
+639911312345,ACTIVE,df329a6b1d857e0c,990 PDL,1234,MH1KF2976KK062071,KF29E7059684,150,Automatic,2019,Approved,Honda,ACB150CBTK,ACB150CBTK,N25-12-010327,2033-01-24,Kim,Indin,Cua,MNL,"passenger_bike","fleet1, fleet2","2027-03-10","2027-04-10","2027-05-10"
+639077412345,ACTIVE,849b36e79d2c1e9c,180100000457335 - 185 QNJ,5678,MH3SEF550N0054832,E31WE0188887,125,Automatic,2023,Approved,Yamaha,Mio Gravis B3U3,Mio Gravis B3U3,N01-22-342747,2027-03-08,Wilbert,Dasigan,Rebusi,MNL,"padala_bike","fleet1, fleet2","2027-03-10","2027-04-10","2027-05-10"`;

        downloadCSVFile(tempData, 'activate_drivers_template_v1');
    };

    const handleFile = (event: any) => {
        setShowDownloadBtn(false);
        papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result: any) {
                const max_rows = 3000;
                if (result.data.length > max_rows) {
                    // Maximum of 3000 rows per upload
                    notification.error({
                        message: 'Error',
                        description: `Maximum of ${max_rows} Rows per upload`,
                        duration: 3,
                    });
                    return;
                }

                let arrayObj = result.data.map((d: any) => {
                    return {
                        phone_number: d.phone_number,
                        status: d.status,
                        imei: d.imei,
                        vehicle_number: d.vehicle_number,
                        plate_number: d.plate_number,
                        chassis_number: d.chassis_number,
                        engine_number: d.engine_number,
                        piston_displacement: Number(d.piston_displacement),
                        transmission_type: d.transmission_type,
                        year: Number(d.year),
                        tnv_status: d.tnv_status,
                        vehicle_make: d.vehicle_make,
                        series: d.series,
                        model: d.model,
                        license_number: d.license_number,
                        license_expired_date: d.license_expired_date,
                        first_name: d.first_name,
                        middle_name: d.middle_name,
                        last_name: d.last_name,
                        service_zone: d.service_zone,
                        service_types: d?.service_types?.split(','),
                        fleet: d.fleet,
                        pa_start_date: d.pa_start_date,
                        pa_expiry_date: d.pa_expiry_date,
                        registration_expiry_date: d.registration_expiry_date,
                    };
                });
                uploadCsv(arrayObj);
            },
        });
    };
    const handleFileUpload = (event: any) => {
        (hiddenFileInput?.current as any)?.click();
    };

    const columns = [
        {
            title: 'Phone Number',
            key: 'phone_number',
            with: 100,
            render: (activate_drivers: any) => activate_drivers?.phone_number,
        },
        {
            title: 'Status',
            render: (activate_drivers: any) => (
                <div className="flex items-start flex-col">
                    <div className="">
                        {activate_drivers.status == 'ACTIVE' && <Tag color="green">Active</Tag>}
                        {activate_drivers.status == 'DEACTIVATED' && <Tag color="red">Deactivated</Tag>}
                        {activate_drivers.status == 'SUSPENDED' && <Tag color="orange">Suspended</Tag>}
                        {activate_drivers.status == 'BANNED' && <Tag>Banned</Tag>}
                        {activate_drivers.status == 'SHADOW_BANNED' && <Tag>Shadow Banned</Tag>}
                        {activate_drivers.status == 'SHADOW_SUSPENDED' && <Tag color="orange">Shadow Suspended</Tag>}
                    </div>
                </div>
            ),
        },
        {
            title: 'Imei',
            key: 'imei',
            render: (activate_drivers: any) => activate_drivers?.imei,
        },
        {
            title: 'Vehicle Number',
            key: 'vehicle_number',
            render: (activate_drivers: any) => activate_drivers?.vehicle_number,
        },
        {
            title: 'Plate Number',
            key: 'plate_number',
            render: (activate_drivers: any) => activate_drivers?.plate_number,
        },
        {
            title: 'Chassis Number',
            key: 'chassis_number',
            render: (activate_drivers: any) => activate_drivers?.chassis_number,
        },
        {
            title: 'Engine Number',
            key: 'engine_number',
            render: (activate_drivers: any) => activate_drivers?.engine_number,
        },
        {
            title: 'Piston Displacement',
            key: 'piston_displacement',
            render: (activate_drivers: any) => activate_drivers.piston_displacement,
        },
        {
            title: 'Transmission Type',
            key: 'transmission_type',
            render: (activate_drivers: any) => activate_drivers?.transmission_type,
        },
        {
            title: 'Year',
            key: 'year',
            render: (activate_drivers: any) => activate_drivers?.year,
        },
        {
            title: 'Tnv Status',
            key: 'tnv_status',
            render: (activate_drivers: any) => activate_drivers?.tnv_status,
        },
        {
            title: 'Vehicle Make',
            key: 'vehicle_make',
            render: (activate_drivers: any) => activate_drivers?.vehicle_make,
        },
        {
            title: 'Series',
            key: 'series',
            render: (activate_drivers: any) => activate_drivers?.series,
        },
        {
            title: 'Model',
            key: 'model',
            render: (activate_drivers: any) => activate_drivers?.model,
        },
        {
            title: 'License Number',
            key: 'license_number',
            render: (activate_drivers: any) => activate_drivers?.license_number,
        },
        {
            title: 'License Expired Date',
            key: 'license_expired_date',
            render: (activate_drivers: any) => activate_drivers?.license_expired_date,
        },
        {
            title: 'First Name',
            key: 'first_name',
            render: (activate_drivers: any) => activate_drivers?.first_name,
        },
        {
            title: 'Middle Name',
            key: 'middle_name',
            render: (activate_drivers: any) => activate_drivers?.middle_name,
        },
        {
            title: 'Last Name',
            key: 'last_name',
            render: (activate_drivers: any) => activate_drivers?.last_name,
        },
        {
            title: 'Service Zone',
            key: 'service_zone',
            render: (activate_drivers: any) => activate_drivers?.service_zone,
        },
        {
            title: 'Service Type',
            key: 'service_types',
            render: (activate_drivers: any) => activate_drivers?.service_types,
        },
        // {
        //     title: 'Fleet',
        //     key: 'fleet',
        //     render: (activate_drivers: any) => activate_drivers?.fleet,
        // },
        {
            title: 'Failed_Message',
            key: 'failed_response',
            render: (activate_drivers: any) => activate_drivers?.failed_response,
        },
    ];

    return (
        <ListLayout goBackLink="/drivers" title={'Activate Bikers'}>
            <div style={{ width: '70%' }} className="flex justify-between">
                <Alert
                    showIcon
                    type="warning"
                    message="Please make sure you follow the correct format before uploading the
                    CSV file."
                />
                <div className="flex flex-row gap-1">
                    {hasActionAccess(PagesEnum.BIKER_ACTIVATION) && (
                        <div className="flex flex-col">
                            <div>
                                <input
                                    hidden
                                    id="upload-file"
                                    type="file"
                                    accept=".csv"
                                    name="file"
                                    ref={hiddenFileInput}
                                    onChange={handleFile}
                                ></input>
                                <Button type="primary" onClick={handleFileUpload} icon={<UploadOutlined />}>
                                    Upload CSV
                                </Button>
                            </div>
                        </div>
                    )}
                    <div>
                        <Button
                            type="ghost"
                            target="_blank"
                            onClick={() => downloadTemplate()}
                            icon={<DownloadOutlined />}
                        >
                            Download Template
                        </Button>
                    </div>
                </div>
            </div>
            {showDownloadBtn ? (
                <div
                    style={{ border: '1px solid #ADB6B9', width: '70%' }}
                    className="flex justify-between items-center m-2 p-4"
                >
                    <div>
                        <ul className="text-sm font-bold p-0">
                            Upload .csv error
                            <li className="block font-medium text-base">{erroNumber} Number of rows have error </li>
                            <li className="block font-medium text-base">{successNumber} Number of rows have success</li>
                        </ul>
                    </div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                border: '1px solid #D9D9D9',
                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                            }}
                            className="bg-inherit px-4 py-2"
                            onClick={() => downloadFailedData()}
                            id="text-button"
                        >
                            Download Failed Rows
                        </button>
                    </div>
                </div>
            ) : null}
            <div style={{ width: '70%' }}>
                <Table
                    columns={columns}
                    loading={loading}
                    // pagination={false}
                    dataSource={tableData}
                    scroll={{ x: 100 }}
                />
            </div>
        </ListLayout>
    );
};

export default UploadActivateDrivers;
