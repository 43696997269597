import { Alert, Button, Spin } from 'antd/lib';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getZoneById, editZone } from 'services/message.service';
import EditZoneModal from './EditZoneModal';
import useModal from 'hooks/useModal';
import DeleteLayerModal from './DeleteLayerModal';
import DeleteZoneModal from './DeleteZoneModal';
import { useNavigate } from 'react-router-dom';
import MapComponent from './MapComponent';
import { Card, CustomNotification } from 'components/basic';

interface Zone {
    id: string;
    name: string;
    created_at: string;
    created_by: string;
    updated_at: string;
    geojson: {
        type: string;
        features: Array<any>;
    };
}

const EditZonePage = () => {
    const navigate = useNavigate();
    const editZoneModal = useModal();
    const deleteZoneModal = useModal();
    const deleteLayerModal = useModal();
    const [zoneData, setZoneData] = useState<Zone[]>([]);
    const { layerId, zoneId } = useParams<{ layerId: string; zoneId: string }>();
    const [polygonCoords, setPolygonCoords] = useState<google.maps.LatLng[] | null>([]);
    const [selectedPlaceName, setSelectedPlaceName] = useState<string>('Unknown Location');
    const [selectedZoneId, setSelectedZoneId] = useState<number | null>(null);
    const [zoneName, setZoneName] = useState<number | null>(null);
    const [mapKey, setMapKey] = useState(0);

    const { request, result, loading } = useApi({
        api: getZoneById,
    });

    useMount(() => {
        fetchZoneById(layerId, zoneId);
    });

    const fetchZoneById = useCallback(
        async (layerId: any, zoneId: any) => {
            try {
                const response = await request({ layerId, zoneId });
                setZoneData(response?.data?.zones || []);
            } catch (error) {
                console.error('Error fetching zone:', error);
            }
        },
        [request],
    );

    const handlePolygonUpdate = (coords: google.maps.LatLng[] | null) => {
        setPolygonCoords(coords);
    };

    const handlePlaceNameUpdate = (name: string) => {
        setSelectedPlaceName(result?.data?.name);
    };

    const handleOnEditZoneModal = useCallback(() => {
        editZoneModal.show();
    }, [editZoneModal]);

    const handleOnDeleteZoneModal = useCallback(
        (zoneId: any, name: any) => {
            setSelectedZoneId(zoneId);
            setZoneName(name);
            deleteZoneModal.show();
        },
        [deleteZoneModal],
    );

    const handleOnDeleteModal = useCallback(() => {
        deleteLayerModal.show();
    }, [deleteLayerModal]);

    const handleGoBack = () => {
        navigate(-1);
    };
    const {
        request: createZone,
        result: resultZone,
        loading: loadingZone,
        error: errorCreateZone,
    } = useApi({
        api: editZone,
    });
    const saveGeoJsonData = useCallback(
        async (geoJsonData: any) => {
            const name = geoJsonData.name;
            const geojson = geoJsonData.geojson;
            const res = await createZone({ layerId, zoneId, name, geojson });
            if (!res.error) {
                CustomNotification({
                    type: 'success',
                    message: 'Success',
                    description: `${name} Zone successfully updated.`,
                });
                fetchZoneById(layerId, zoneId);
                setPolygonCoords(null);
                setMapKey(prevKey => prevKey + 1);
            } else {
                CustomNotification({
                    type: 'error',
                    message: 'Error',
                    description: <p className="capitalize">{res.error.message}</p>,
                });
            }
        },
        [createZone, fetchZoneById, layerId, zoneId],
    );
    const handleEditZone = useCallback(() => {
        const body = {
            id: layerId,
            name: result?.data?.name,
            geojson: {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: {
                            name: result?.data?.name,
                        },
                        geometry: {
                            type: 'Polygon',
                            coordinates: [polygonCoords?.map(coord => [coord.lng(), coord.lat()])],
                        },
                    },
                ],
            },
            properties: {
                name: result?.data?.name,
            },
        };

        saveGeoJsonData(body);
    }, [polygonCoords, layerId, result?.data?.name, saveGeoJsonData]);

    return (
        <div className="flex">
            <div className="h-screen w-1/4 bg-[#E6F7FF]">
                <div className="bg-[#40A9FF] p-6">
                    <a className="text-white text-sm cursor-pointer hover:text-gray-200" onClick={handleGoBack}>
                        ← Go Back
                    </a>
                </div>
                {!loading ? (
                    <div>
                        <div className="flex justify-between p-6">
                            <div>
                                <span>Zone Name</span>
                                <h5 className="font-extrabold">{result?.data?.name}</h5>
                            </div>
                            <div>
                                <Button onClick={() => handleOnEditZoneModal()}>Edit</Button>
                                <Button onClick={() => handleOnDeleteZoneModal(result?.data?.id, result?.data?.name)}>
                                    Delete
                                </Button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <Card className="mt-14">
                        <Spin />
                    </Card>
                )}
            </div>
            <div className="w-3/4">
                <div className="bg-white p-4 flex justify-end">
                    <Button onClick={() => handleOnDeleteModal()} className="bg-white mr-2">
                        Delete Layer
                    </Button>
                    <Button type="primary" onClick={() => handleEditZone()}>
                        Save
                    </Button>
                </div>
                <div className="px-3">
                    <div className="m-2 ml-0 w-1/2">
                        <Alert
                            message="In order to draw polygon you need to click on map where you need zones"
                            type="warning"
                            showIcon
                        />
                    </div>
                    <MapComponent
                        editableMode="full"
                        initialGeojson={result?.data?.geojson}
                        onPolygonUpdate={handlePolygonUpdate}
                        onPlaceNameUpdate={handlePlaceNameUpdate}
                    />
                </div>
            </div>
            <EditZoneModal
                modal={editZoneModal}
                onSuccess={() => {
                    fetchZoneById(layerId, zoneId);
                }}
                name={result?.data?.name}
                geojson={result?.data?.geojson}
            />
            <DeleteZoneModal
                modal={deleteZoneModal}
                zoneId={result?.data?.id}
                layerId={layerId}
                zoneName={result?.data?.name}
                onSuccess={() => handleGoBack()}
            />
            <DeleteLayerModal layerId={layerId} modal={deleteLayerModal} onSuccess={() => navigate('/zones')} />
        </div>
    );
};

export default EditZonePage;
