import { Alert, Button, Skeleton } from 'antd/lib';
import { Card, Text } from 'components/basic';
import React, { useMemo } from 'react';
import useModal from 'hooks/useModal';
import useApi from 'hooks/useApi';
import { getFleet, getFleets } from 'services/message.service';
import useMount from 'hooks/useMount';
import FleetForm from './FleetForm';
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import CreateFleetModal from './CreateFleetModal';

const FleetManagement = () => {
    const { getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const [claims, setClaims] = React.useState<any>(null);
    const fleetModal = useModal();
    const createfleetModal = useModal();
    const defaultFleet = process.env.REACT_APP_DEFAULT_FLEET_ID || '';
    const {
        request: requestFleets,
        result: fleetsData,
        loading,
    } = useApi({
        api: getFleets,
    });

    useMount(() => {
        requestFleets();
        const detectUser = async () => {
            const accessToken = await getAccessTokenSilently();
            if (accessToken) {
                getIdTokenClaims().then(claims => {
                    setClaims(claims);
                });
            }
        };
        detectUser();
    });

    const { fleets } = fleetsData?.data || {};

    const sortFleets = (fleets: any) => {
        fleets?.sort((a: any, b: any) => {
            return b.fleet_priority - a.fleet_priority;
        });
        return fleets;
    };

    const sortedFleets = useMemo(() => {
        return sortFleets(fleets);
    }, [fleets]);

    const { request: requestFleet } = useApi({
        api: getFleet,
    });

    const handleOnShowModal = async (id: string) => {
        fleetModal.show({
            id: id,
            fetchFleet: () => requestFleet({ id }),
        });
    };

    return (
        <Card>
            <div className="flex">
                <Link className="text-gray-500 text-sm cursor-pointer hover:text-blue" to={'/settings'}>
                    ← Back to Settings
                </Link>
            </div>
            <Text type="heading" className="mb-md">
                Fleet Management
            </Text>
            <div className="flex gap-8">
                <div className="w-96">
                    <Text type="title" color="text-gray-600">
                        Manage Priority
                    </Text>
                    <Text type="label">
                        Enhance drivers' efficiency by expertly assigning optimal fleet priorities, boosting operational
                        performance.
                    </Text>
                </div>
                <div className="flex-1 gap-4">
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <div className="flex flex-col gap-4">
                            <div>
                                {claims?.user_role !== 'SUPER_ADMIN' && claims?.user_role !== 'OPS_STRATEGY_2' ? (
                                    <Alert
                                        message={
                                            <div>
                                                To create a new fleet,{' '}
                                                <a
                                                    href="https://app.clickup.com/14274986/v/fm/dkmda-12587"
                                                    target="_blank"
                                                >
                                                    request a ticket
                                                </a>{' '}
                                                through Click-Up.
                                            </div>
                                        }
                                        type="info"
                                        showIcon
                                    />
                                ) : (
                                    <div className="text-right">
                                        <Button type="primary" onClick={() => createfleetModal.show()}>
                                            Add New Fleet
                                        </Button>
                                    </div>
                                )}
                            </div>

                            {claims?.user_role != 'SUPER_ADMIN' && (
                                <Alert
                                    message={
                                        <div>
                                            Fleet can be created and updated by a Super Admin and Stategy 2 account.
                                        </div>
                                    }
                                    type="warning"
                                    showIcon
                                />
                            )}
                            <div className="col-span-2 flex flex-col gap-4">
                                {sortedFleets?.map((fleet: any, index: number) => {
                                    if (fleet.id === defaultFleet) return;
                                    return (
                                        <div key={fleet.id}>
                                            <FleetForm
                                                claims={claims}
                                                fleet={fleet}
                                                handleOnShowModal={handleOnShowModal}
                                                refreshList={requestFleets}
                                                count={index + 1}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <CreateFleetModal fleetModal={createfleetModal} refreshList={requestFleets} />
        </Card>
    );
};

export default FleetManagement;
