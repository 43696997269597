import { CustomNotification, Text, Input } from 'components/basic';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDriversList, getFleet, removeFleetDrivers } from 'services/message.service';
import { DeleteOutlined, CloseOutlined, SearchOutlined } from '@ant-design/icons';
import classnames from 'clsx';
import { Empty, Button, Skeleton, Modal, Divider } from 'antd/lib';
import Field from 'components/basic/Field/Field';
import FleetModal from './FleetModal';
import useModal from 'hooks/useModal';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const ViewFleetsPage = () => {
    const fleetModal = useModal();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [fleetInfo, setFleetInfo] = useState<any | null>(null);
    const [fleetDrivers, setFleetDrivers] = useState<any | null>(null);
    const defaultFleet = process.env.REACT_APP_DEFAULT_FLEET_ID || '';
    const [selectedAll, setSelectedAll] = useState<boolean>(false);
    const confirmationModal = useModal();
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return getPermissions(userRole, page)?.includes(Accessibility.ALL);
    };

    let { id } = useParams();

    const { request: removeDrivers, loading: removingFleetDrivers } = useApi({
        api: removeFleetDrivers,
    });
    const { request: requestDrivers } = useApi({ api: getDriversList });

    const { modifyFilters, requestState } = useFilter({
        page_size: 20,
        page: 1,
        search_by: '',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
    });

    useMount(() => {
        fetchDriversList(requestState);
        fetchFleet();
    });

    const fetchDriversList = useCallback(
        async (requestState: any) => {
            try {
                const result = await requestDrivers(requestState);
                const d = result.data;

                if (d.status === 'success') {
                    const pagination = d.data.pagination;
                    const p = {
                        current: pagination.page,
                        defaultCurrent: 20,
                        total: pagination.total_count,
                    };
                    await modifyFilters({
                        page: p.current + 1,
                    });
                } else {
                    // TODO: error screen
                }
            } catch (error) {
                throw error;
            }
        },
        [requestDrivers, modifyFilters],
    );

    const { request: requestFleet, loading: fetchingFleet } = useApi({
        api: getFleet,
    });

    const fetchFleet = useCallback(async () => {
        const res = await requestFleet({ id });
        if (res) {
            setFleetInfo(res.data);
            setFleetDrivers(res.data.drivers);
        }
    }, [requestFleet, id]);

    const handleDeleteDrivers = async () => {
        const res = await removeDrivers({ id: id, drivers: selectedRowKeys });

        if (res.status === 'success') {
            showSuccessNotification('Successfully Removed Drivers.');
            setSelectedRowKeys([]);
            fetchFleet();
            setSelectedAll(false);
        } else {
            showErrorNotification(res.error.message);
            setSelectedRowKeys([]);
        }
        confirmationModal.close();
    };

    const showSuccessNotification = (message: string) => {
        CustomNotification({
            type: 'success',
            message: 'Success',
            description: message,
        });
    };

    const showErrorNotification = (message: string) => {
        CustomNotification({
            type: 'error',
            message: 'Error',
            description: message,
        });
    };

    const handleSearchExistingDrivers = (event: any) => {
        const { value: query } = event.target;
        if (fleetInfo && fleetInfo.drivers) {
            const filteredItems = fleetInfo.drivers.filter((driver: any) => {
                const fullName = `${driver.first_name} ${driver.last_name}`;
                return (
                    fullName.toLowerCase().includes(query.toLowerCase()) ||
                    driver.phone_number?.includes(query.toLowerCase()) ||
                    driver.public_id.toLowerCase()?.includes(query.toLowerCase())
                );
            });
            setFleetDrivers(filteredItems);
        }
    };

    const {
        driver_count,
        commission,
        fleet_description,
        fleet_name,
        fleet_priority,
        id: fleetId,
        jalopy_id,
    } = fleetInfo || {};

    const selectAll = () => {
        const allDriverIds = fleetInfo?.drivers?.map((driver: any) => driver.id) || [];
        setSelectedRowKeys(allDriverIds);
        setSelectedAll(true);
    };

    const deselectAll = () => {
        setSelectedRowKeys([]);
        setSelectedAll(false);
    };

    const handleOnShowModal = async (id: string) => {
        fleetModal.show({
            id: id,
            fetchFleet: () => requestFleet({ id }),
        });
    };

    return (
        <DetailContentLayout goBackLink="/settings/fleet-management">
            <div className="flex flex-col gap-4 mt-4">
                <div className="bg-white rounded p-lg border border-dashed border-slate-200">
                    {!fetchingFleet ? (
                        <div className="flex-1 flex flex-row items-center">
                            <div className="flex flex-row flex-1 items-center gap-12">
                                <div className="lg:flex items-center justify-between">
                                    <div className="flex items-center gap-4">
                                        <div>
                                            <Text type="title">{fleet_name}</Text>
                                            <Text type="description" color="text-gray">
                                                {fleet_description}
                                            </Text>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-8 gap-y-2">
                                    <Field label="Number of Drivers" value={driver_count || 0} />
                                    <Field label="Fleet Priority" value={fleet_priority} />
                                    {/* <Field label="Fleet ID" value={fleetId} className='col-span-2'/> */}
                                    <div className="col-span-2">
                                        <Text type="label">Internal ID</Text>
                                        <Text type="uuid">{fleetId}</Text>
                                    </div>
                                </div>
                            </div>

                            {(hasActionAccess(PagesEnum.GENERAL_SETTINGS) || userRole === 'OPS_STRATEGY_2') && (
                                <Button
                                    type={defaultFleet === fleetInfo?.id ? 'ghost' : 'primary'}
                                    onClick={() => handleOnShowModal(id + '')}
                                    disabled={defaultFleet === fleetInfo?.id}
                                >
                                    Add Drivers
                                </Button>
                            )}
                        </div>
                    ) : (
                        <Skeleton />
                    )}
                </div>
                <div className="flex-1 flex gap-4 flex-col">
                    <Divider dashed>Manage Drivers</Divider>
                    <div>
                        <div className="flex justify-between items-center mb-md">
                            <div className="w-1/2">
                                <Input
                                    className="rounded-full p-lg"
                                    size="large"
                                    placeholder="Search Drivers"
                                    onChange={handleSearchExistingDrivers}
                                    prefix={<SearchOutlined />}
                                    allowClear
                                />
                            </div>
                            {defaultFleet !== fleetInfo?.id && (
                                <div className="flex gap-1 items-center">
                                    <Text type="label">
                                        {`${
                                            selectedRowKeys?.length > 0
                                                ? `${
                                                      selectedRowKeys?.length > 1
                                                          ? `${selectedRowKeys?.length} drivers selected`
                                                          : `${selectedRowKeys?.length} driver selected`
                                                  } `
                                                : 'No selected drivers'
                                        }`}
                                    </Text>
                                    {fleetDrivers && hasActionAccess(PagesEnum.GENERAL_SETTINGS) && (
                                        <Button
                                            className={classnames(
                                                'cursor-pointer text-gray',
                                                { 'hover:text-blue': selectedAll },
                                                { 'hover:text-red-400': !selectedAll },
                                            )}
                                            onClick={selectedAll ? deselectAll : selectAll}
                                        >
                                            {selectedAll || selectedRowKeys.length + 1 === fleetDrivers?.length + 1
                                                ? 'Deselect All'
                                                : 'Select All'}
                                        </Button>
                                    )}
                                    {hasActionAccess(PagesEnum.GENERAL_SETTINGS) && (
                                        <Button
                                            danger={fleetDrivers || selectedRowKeys.length !== 0}
                                            onClick={() =>
                                                confirmationModal.show({
                                                    onOk: handleDeleteDrivers,
                                                    title: 'Remove Selected Drivers',
                                                    okText: 'Yes',
                                                    okType: 'danger',
                                                    children:
                                                        'You are about to remove the selected drivers. Do you wish to continue?',
                                                })
                                            }
                                            disabled={!fleetDrivers || selectedRowKeys.length === 0}
                                            loading={removingFleetDrivers}
                                        >
                                            Remove Selected Drivers
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                        {fleetDrivers && (
                            <div className={fleetDrivers.length > 6 ? 'pr-md' : 'pr-xs'}>
                                <div className="grid grid-cols-8 pl-4 pr-6 pb-sm gap-4">
                                    <div>
                                        <Text className="text-black-lighter">Driver ID</Text>
                                    </div>
                                    <div className="col-span-3">
                                        <Text className="text-black-lighter">Name</Text>
                                    </div>
                                    <div className="col-span-2">
                                        <Text className="text-black-lighter">Phone Number</Text>
                                    </div>
                                    <div className="col-span-2 text-end">
                                        <Text className="text-black-lighter">Actions</Text>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="rounded">
                            {!fetchingFleet ? (
                                <div
                                    className={classnames(
                                        { 'h-full': !fleetDrivers },
                                        'gap-y-2 flex flex-col justify-center',
                                    )}
                                >
                                    {fleetDrivers ? (
                                        fleetDrivers.map((driver: any) => {
                                            const { first_name, last_name, id, public_id, date_added, phone_number } =
                                                driver;
                                            const isSelected = selectedRowKeys.includes(id);
                                            return (
                                                <div
                                                    key={id}
                                                    className={classnames(
                                                        isSelected
                                                            ? 'bg-red-100 border-red-300'
                                                            : 'bg-white border-gray-100',
                                                        'grid grid-cols-8 bg-white px-4 py-4 gap-4 rounded border border-solid',
                                                    )}
                                                >
                                                    <div>
                                                        <a href={`/drivers/${id}/overview`} target="_blank">
                                                            {public_id}
                                                        </a>
                                                    </div>
                                                    <div className="col-span-3">{`${first_name} ${last_name}`}</div>
                                                    <div className="col-span-2">
                                                        {phone_number ? (
                                                            phone_number
                                                        ) : (
                                                            <Text color="text-gray">No Phone Number</Text>
                                                        )}
                                                    </div>
                                                    <div className="col-span-2 flex justify-end gap-2">
                                                        {isSelected ? (
                                                            <span className="text-red-500">For Removal</span>
                                                        ) : (
                                                            <a
                                                                href={`/drivers/${id}/overview`}
                                                                target="_blank"
                                                                className="text-gray-300"
                                                            >
                                                                View Driver Profile
                                                            </a>
                                                        )}
                                                        {defaultFleet !== fleetInfo?.id &&
                                                            hasActionAccess(PagesEnum.GENERAL_SETTINGS) && (
                                                                <Button
                                                                    type="ghost"
                                                                    danger
                                                                    size="small"
                                                                    className="p-0 text-gray-200"
                                                                    shape="circle"
                                                                    onClick={() => {
                                                                        if (isSelected) {
                                                                            setSelectedRowKeys(
                                                                                selectedRowKeys.filter(
                                                                                    key => key !== id,
                                                                                ),
                                                                            );
                                                                        } else {
                                                                            setSelectedRowKeys([
                                                                                ...selectedRowKeys,
                                                                                id,
                                                                            ]);
                                                                        }
                                                                    }}
                                                                >
                                                                    {isSelected ? (
                                                                        <CloseOutlined />
                                                                    ) : (
                                                                        <DeleteOutlined />
                                                                    )}
                                                                </Button>
                                                            )}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    ) : (
                                        <Empty description="No data available" />
                                    )}
                                </div>
                            ) : (
                                <Skeleton />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FleetModal fleetModal={fleetModal} refreshList={fetchFleet} />
            <Modal {...confirmationModal} />
        </DetailContentLayout>
    );
};

export default ViewFleetsPage;
