import { Button, DatePicker, Form, Select } from 'antd/lib';
import { Input, Text } from 'components/basic';
import React from 'react';
const { Option } = Select;

interface VouchersFilterProps {
    onFilterSubmit: (values: { field: string; keyword: string; created_date: string; expiry_date: string }) => void;
    onFilterReset: () => void;
    loading: boolean | undefined;
}

const VouchersFilter: React.FC<VouchersFilterProps> = ({ onFilterSubmit, loading, onFilterReset }) => {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        onFilterSubmit(values);
    };

    const onReset = () => {
        form.resetFields();
        onFilterReset();
    };

    return (
        <div className="flex items-end gap-2">
            <Form
                className="flex flex-1 items-center gap-1"
                form={form}
                onFinish={onFinish}
                layout="inline"
                initialValues={{
                    field: 'public_id',
                    keyword: '',
                    created_at: '',
                    expires_at: '',
                }}
            >
                <div>
                    <Text type="label">Search by name, phone number, or Internal Driver ID</Text>
                    <div className="flex">
                        <Form.Item name="field" className="w-32">
                            <Select>
                                <Option value="public_id">Internal Driver ID</Option>
                                <Option value="voucher_id">Voucher Code</Option>
                                <Option value="amount">Amount</Option>
                                <Option value="source">Source</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="keyword" className="rounded-full p-lg w-64">
                            <Input className="rounded-full p-lg" placeholder="Enter search keyword..." />
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <Text type="label">Created Date</Text>
                    <Form.Item name="created_at" valuePropName={'date'}>
                        <DatePicker />
                    </Form.Item>
                </div>
                <div>
                    <Text type="label">Expiry Date</Text>
                    <Form.Item name="expires_at" valuePropName={'date'}>
                        <DatePicker />
                    </Form.Item>
                </div>
                <div className="flex items-center self-end">
                    <Form.Item label="">
                        <Button type="primary" ghost loading={loading} disabled={loading} htmlType="submit">
                            Apply Filter
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button disabled={loading} onClick={onReset}>
                            Clear Filters
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default VouchersFilter;
