import React, { useState, useCallback } from 'react';
import { Button, Input, Text } from 'components/basic';
import useApi from 'hooks/useApi';
import { getOperatorsList } from 'services/message.service';
import useMount from 'hooks/useMount';
import { Select } from 'antd/lib';

interface FinanceAuditLogsFilterProps {
    onFilterSubmit: (values: { operatorId: string; dataType: string; dataChange: string }) => void;
    onFilterReset?: () => void;
}

const FinanceAuditLogsFilter: React.FC<FinanceAuditLogsFilterProps> = ({ onFilterSubmit, onFilterReset }) => {
    const [selectedOperatorId, setSelectedOperatorId] = useState('');
    const [selectedDataType, setSelectedDataType] = useState('');
    const [selectedDataChange, setSelectedDataChange] = useState('');
    const [operators, setOperators] = useState([{ value: '', label: 'All Operators' }]);

    const handleFilterSubmit = useCallback(() => {
        onFilterSubmit({
            operatorId: selectedOperatorId,
            dataType: selectedDataType,
            dataChange: selectedDataChange,
        });
    }, [onFilterSubmit, selectedOperatorId, selectedDataType, selectedDataChange]);

    const handleFilterReset = useCallback(() => {
        setSelectedOperatorId('');
        setSelectedDataType('');
        setSelectedDataChange('');
        onFilterSubmit({ operatorId: '', dataType: '', dataChange: '' });
    }, [setSelectedOperatorId, onFilterSubmit]);

    const { request: getOperatorsRequest, loading } = useApi({
        api: getOperatorsList,
    });

    useMount(() => {
        fetchOperators('');
    });

    const fetchOperators = useCallback(
        async (searchKey: '') => {
            try {
                const result = await getOperatorsRequest({
                    page_size: 100,
                    page: 1,
                    search_key: searchKey,
                });
                let d = result.data.data;
                let operator = d.operators.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                let options = [{ value: '', label: 'All Operators' }];
                operator.forEach(function (d: any) {
                    options.push({
                        value: d.id,
                        label: d.first_name + ' ' + d.last_name,
                    });
                });
                setOperators(options);
            } catch (error) {
                throw error;
            }
        },
        [getOperatorsRequest],
    );

    const handleOperatorIdChanged = useCallback(
        (value: any) => {
            setSelectedOperatorId(value);
        },
        [setSelectedOperatorId],
    );

    const handleDataTypeChanged = useCallback(
        (value: any) => {
            setSelectedDataType(value);
        },
        [setSelectedDataType],
    );

    const handleDataChangeChanged = useCallback(
        (value: any) => {
            setSelectedDataChange(value);
        },
        [setSelectedDataChange],
    );

    const handleSearchOperator = useCallback(
        (value: any) => {
            if (value != '') {
                fetchOperators(value);
            }
        },
        [fetchOperators],
    );

    const dataTypeOptions = [
        { value: '', label: 'All Data Types' },
        {
            value: 'DRIVER',
            label: 'Driver',
        },
        {
            value: 'CUSTOMER',
            label: 'Customer',
        },
        {
            value: 'AUDIT_LOG',
            label: 'Audit Log',
        },
        {
            value: 'OPERATOR',
            label: 'Operator',
        },
    ];

    const dataChangeOptions = [
        { value: '', label: 'All Actions' },
        {
            value: 'READ',
            label: 'Read',
        },
        {
            value: 'CREATE',
            label: 'Create',
        },
        {
            value: 'UPDATE',
            label: 'Update',
        },
        {
            value: 'DELETE',
            label: 'Delete',
        },
    ];

    return (
        <div className="flex items-end gap-2">
            <div className="block">
                <Text type="label">Operator</Text>
                <Select
                    className="w-96"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Operator"
                    onChange={handleOperatorIdChanged}
                    onSearch={handleSearchOperator}
                    filterOption={(input, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={operators}
                    defaultValue={operators[0].value}
                    value={selectedOperatorId}
                />
            </div>
            <div className="block">
                <Text type="label">Data Type</Text>
                <Select
                    className="w-64"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Data Type"
                    onChange={handleDataTypeChanged}
                    filterOption={(input, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={dataTypeOptions}
                    value={selectedDataType}
                />
            </div>
            <div className="block">
                <Text type="label">Action</Text>
                <Select
                    className="w-36"
                    showSearch
                    optionFilterProp="children"
                    placeholder="Select Action"
                    onChange={handleDataChangeChanged}
                    filterOption={(input, option: any) =>
                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={dataChangeOptions}
                    value={selectedDataChange}
                />
            </div>
            <div className="block">
                <div className="flex items-center gap-1">
                    <Button type="primary" ghost onClick={handleFilterSubmit} disabled={loading} loading={loading}>
                        Apply Filter
                    </Button>
                    <Button onClick={handleFilterReset} disabled={loading}>
                        Reset
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default FinanceAuditLogsFilter;
