import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, Button, Modal, Select } from 'antd/lib';
import { CloseOutlined } from '@ant-design/icons';
import useApi from 'hooks/useApi';
import { addFleetDrivers, getDriversList, removeFleetDrivers, replaceFleetDrivers } from 'services/message.service';
import useMount from 'hooks/useMount';
import useFilter from 'hooks/useFilter';
import { CustomNotification, Text } from 'components/basic';
import useModal from 'hooks/useModal';

interface FleetModalProps {
    fleetModal: any;
    refreshList: any;
}

const FleetModal: React.FC<FleetModalProps> = ({ fleetModal, refreshList }) => {
    const [data, setData] = useState<any[]>([]);
    const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);
    const [fleetInfo, setFleetInfo] = useState<any | null>(null);
    const confirmationModal = useModal();

    const drivers = useMemo(() => {
        return data.map(d => ({
            value: d.id,
            jalopy_id: d.jalopy_id + '',
            public_id: d.public_id,
            label: `${d.first_name} ${d.last_name}`,
        }));
    }, [data]);

    const getInvalidCount = () => {
        return selectedDrivers.filter(
            driver =>
                !drivers.some(
                    option => option.value === driver || option.jalopy_id === driver || option.public_id === driver,
                ),
        ).length;
    };

    const handleChange = async (value: any) => {
        let filterState = {
            ...requestState,
            page: 1,
            driver_ids: value.join(),
        };
        await fetchDriversList(filterState);
        setSelectedDrivers(value);
    };

    const { request: replaceDrivers } = useApi({ api: replaceFleetDrivers });
    const { request: assignFleetDrivers } = useApi({ api: addFleetDrivers });
    const { request: requestDrivers, loading } = useApi({ api: getDriversList });

    const { modifyFilters, requestState } = useFilter({
        page_size: 20,
        page: 1,
        driver_ids: '',
    });

    useMount(() => {
        fetchDriversList(requestState);
    });

    const fetchDriversList = useCallback(
        async (requestState: any) => {
            try {
                const result = await requestDrivers(requestState);
                const d = result.data;

                if (d.status === 'success') {
                    const drivers = d.data.drivers.map((c: any) => ({
                        ...c,
                        key: c.id,
                    }));
                    setData(data => {
                        const newDrivers = drivers.filter(
                            (driver: any) => !data.some(existingDriver => existingDriver.id === driver.id),
                        );
                        return [...data, ...newDrivers];
                    });
                    const pagination = d.data.pagination;
                    const p = {
                        current: pagination.page,
                        defaultCurrent: 20,
                        total: pagination.total_count,
                    };
                    await modifyFilters({
                        page: p.current + 1,
                    });
                } else {
                    // TODO: error screen
                }
            } catch (error) {
                throw error;
            }
        },
        [requestDrivers, modifyFilters],
    );

    const handlePopupScroll = (event: any) => {
        const scrollBottom = event.currentTarget.scrollTop + event.currentTarget.clientHeight;
        const contentHeight = event.currentTarget.scrollHeight;

        if (!loading && scrollBottom >= contentHeight - 200) {
            fetchDriversList(requestState);
        }
    };

    const selectRef = useRef(null);

    const handleReplaceDrivers = async () => {
        const res = await replaceDrivers({ id: fleetModal.id, drivers: selectedDrivers });
        if (res.status === 'success') {
            showSuccessNotification('Successfully Replaced Drivers.');
            clearSelectedValues();
            refreshList();
            confirmationModal.close();
            fleetModal.close();
        } else {
            showErrorNotification(res.error.message);
        }
    };

    const handleAddDrivers = async () => {
        const res = await assignFleetDrivers({
            id: fleetModal.id,
            drivers: selectedDrivers,
        });
        if (res.status === 'success') {
            showSuccessNotification('Successfully Added Drivers.');
            clearSelectedValues();
            refreshList();
            fleetModal.close();
        } else {
            showErrorNotification(res.error.message);
        }
    };

    const clearSelectedValues = () => {
        setSelectedDrivers([]);
    };

    const fetchFleet = useCallback(async () => {
        const res = await fleetModal.fetchFleet();
        if (res) {
            setFleetInfo(res.data);
        }
    }, [fleetModal]);

    useEffect(() => {
        if (fleetModal.open) {
            fetchFleet();
        }
    }, [fetchFleet, fleetModal.open]);

    const handleOnClose = () => {
        refreshList();
        clearSelectedValues();
        fleetModal.close();
    };

    const showSuccessNotification = (message: string) => {
        CustomNotification({
            type: 'success',
            message: 'Success',
            description: message,
        });
    };

    const showErrorNotification = (message: string) => {
        CustomNotification({
            type: 'error',
            message: 'Error',
            description: message,
        });
    };

    const handleTagClose = useCallback(
        (removedValue: any) => {
            const newSelectedValues = selectedDrivers.filter(value => value !== removedValue);
            setSelectedDrivers(newSelectedValues);
        },
        [selectedDrivers],
    );

    const tagRender = useCallback(
        (props: any) => {
            const { value } = props;

            const driver = drivers.find(
                driver => driver.value === value || driver.jalopy_id === value || driver.public_id === value,
            );

            const tagStyle = !driver ? { backgroundColor: '#ff4d4f', color: 'white' } : {};

            return (
                <div className={`ant-select-selection-item ${!driver ? 'invalid-tag' : ''}`} style={tagStyle}>
                    {driver ? driver?.label : props.value}
                    <span
                        className="ant-select-selection-item-remove ml-sm"
                        onClick={() => {
                            handleTagClose(value);
                        }}
                    >
                        <CloseOutlined />
                    </span>
                </div>
            );
        },
        [drivers, handleTagClose],
    );

    return (
        <div>
            <Modal
                {...fleetModal}
                title={`Manage Fleet Drivers -  ${fleetInfo?.fleet_name}`}
                footer={
                    <div className="flex justify-end my-xs">
                        <Button
                            danger={selectedDrivers.length > 0}
                            disabled={selectedDrivers.length === 0 || getInvalidCount() > 0}
                            onClick={() => {
                                confirmationModal.show({
                                    onOk: handleReplaceDrivers,
                                    title: 'Replace Drivers',
                                    okText: 'Yes',
                                    okType: 'danger',
                                    children:
                                        'You are about to replace all the existing drivers by the newly selected ones. Do you wish to continue?',
                                });
                            }}
                        >
                            Add and Replace Drivers
                        </Button>
                        <Button
                            type={selectedDrivers.length === 0 || getInvalidCount() > 0 ? 'ghost' : 'primary'}
                            disabled={selectedDrivers.length === 0 || getInvalidCount() > 0}
                            onClick={handleAddDrivers}
                        >
                            Add Drivers
                        </Button>
                    </div>
                }
                onCancel={handleOnClose}
            >
                <div className="flex flex-col gap-2">
                    <div className="flex flex-col gap-2">
                        <div className="flex justify-between items-center">
                            <Text fontWeight="font-semibold" color="text-black-lighter">
                                Add More Drivers
                            </Text>
                            <Text color="text-black-lighter">
                                {`${selectedDrivers.length} selected${
                                    getInvalidCount() > 0 ? `,${getInvalidCount()} invalid` : ''
                                }`}
                            </Text>
                        </div>
                        <Select
                            value={selectedDrivers}
                            onPopupScroll={handlePopupScroll}
                            ref={selectRef}
                            mode="tags"
                            style={{ width: '100%' }}
                            onChange={handleChange}
                            tokenSeparators={[',', ' ', '\n', '\t']}
                            options={drivers}
                            allowClear
                            tagRender={tagRender}
                        />
                        {getInvalidCount() > 0 && (
                            <Alert
                                message={
                                    <div>
                                        Seems like you entered invalid ID/s. Please remove it to enable action buttons.
                                    </div>
                                }
                                type="error"
                            />
                        )}
                        <Alert
                            message={
                                <div>
                                    Tip: You can also copy multiple lines from a CSV file and paste the values here.
                                </div>
                            }
                            type="info"
                        />
                    </div>
                </div>
            </Modal>
            <Modal {...confirmationModal} />
        </div>
    );
};

export default FleetModal;
