import { Form, Input } from 'antd/lib';
import Modal from 'antd/lib/modal/Modal';
import { CustomNotification } from 'components/basic';
import useApi from 'hooks/useApi';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { editZone } from 'services/message.service';

interface EditZoneModalProps {
    modal: any;
    onSuccess?: () => void;
    name?: string;
    geojson?: any;
}

const EditZoneModal: React.FC<EditZoneModalProps> = ({ modal, onSuccess, name, geojson }) => {
    const [form] = Form.useForm();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const { layerId, zoneId } = useParams<{ layerId: string; zoneId: string }>(); // Extract layerId and zoneId from the URL

    const [initialValues, setInitialValues] = useState({
        name: '',
    });

    useEffect(() => {
        if (modal.show) {
            setInitialValues({
                name: name || '',
            });
            form.setFieldsValue({
                name: name || '',
            });
        }
    }, [modal.show, name, form, geojson]);

    const handleOnClose = () => {
        modal.close();
        form.resetFields();
    };

    const { request, loading } = useApi({
        api: editZone,
    });

    const handleFormChange = (changedValues: any) => {
        setIsFormDirty(true);
    };

    const editZones = async (layerId: any, zoneId: any, name: string, geojson: any) => {
        const res = await request({
            layerId,
            zoneId,
            name,
            geojson,
        });
        if (!res?.error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: (
                    <p>
                        <b>{res?.data?.name}</b> Zone Updated
                    </p>
                ),
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: res?.error?.message,
            });
        }
    };

    const handleFormFinish = async () => {
        form.validateFields()
            .then(values => {
                const { name } = values;
                editZones(layerId, zoneId, name, geojson);
            })
            .catch(errorInfo => {
                console.error('Validation failed:', errorInfo);
            });
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Edit Zone"
            onOk={handleFormFinish}
            okText="Save"
            okButtonProps={{
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <Form form={form} initialValues={initialValues} onValuesChange={handleFormChange}>
                <Form.Item name="name" label="Zone Name" rules={[{ required: true, message: 'Field is required' }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditZoneModal;
