import { Button, Table } from 'components';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { getDriversTripHistory } from 'services/message.service';
import { columns } from './columns';
import DriverTripHistoryFilter from './DriverTripHistoryFilter/DriverTripHistoryFilter';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Tag, notification } from 'antd/lib';

const DriverTripHistory = ({ id }: { id: string | undefined }) => {
    const [data, setData] = useState([]);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });
    const navigate = useNavigate();
    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        id,
        status: 'all',
        payment_type: null,
        start: null,
        end: null,
    });

    const { request, result, loading } = useApi({
        api: getDriversTripHistory,
    });

    useMount(() => {
        fetchTripHistory(
            {
                id,
                ...requestState,
            },
            false,
        );
    });

    const fetchTripHistory = useCallback(
        async (requestState: {}, onNext: boolean) => {
            try {
                const result = await request(requestState);
                const d = result.data;
                let trips = d?.data?.history?.map((c: any, index: number) => {
                    return {
                        ...c,
                        key: index,
                    };
                });
                setData(trips);
                let pagination = d.data.pagination;
                let p = {
                    ...pagination,
                    current: pagination.page,
                    defaultCurrent: 1,
                    pageSize: pagination.page_size,
                    total: pagination.total_count,
                };
                setPagination(p);
                if (result.error) {
                    notification.error({
                        message: 'Error',
                        description: "Error fetching driver's trip history. Please try again later.",
                        duration: 3,
                    });
                }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;
            const { requestState } = await modifyFilters({
                page_size: pageSize,
                page: current,
                sort_key: field,
                sort_by: order == 'ascend' ? 'asc' : 'desc',
            });
            await fetchTripHistory(requestState || {}, false);
        },
        [fetchTripHistory, modifyFilters],
    );

    const onFilterSubmit = async (filter: any) => {
        const dateNow = moment().utcOffset('+08:00').format('YYYY-MM-DDTHH:mm:ss[Z]');
        let start = '';
        let end = '';

        if (filter.trip_date === 'TODAY') {
            start = moment().utcOffset('+08:00').startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
            end = dateNow;
        } else if (filter.trip_date === 'PAST_WEEK') {
            // Calculate start and end for the past week
            start = moment().utcOffset('+08:00').subtract(7, 'days').startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
            end = dateNow;
        } else if (filter.trip_date === 'CUSTOM' && filter.date_range && filter.date_range.length === 2) {
            start = moment(filter.date_range[0]).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
            end = moment(filter.date_range[1]).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        }

        modifyFilters({
            status: filter.status,
            payment_type: filter.payment_type,
            trip_date: filter.trip_date,
            start: start,
            end: end,
        });
        await fetchTripHistory(
            {
                ...requestState,
                page: 1,
                status: filter.status,
                payment_type: filter.payment_type,
                trip_date: filter.trip_date,
                start: start,
                end: end,
            },
            false,
        );
    };

    const onRefresh = useCallback(async () => {
        setIsRefreshing(true);
        await fetchTripHistory(
            {
                id,
                ...requestState,
            },
            false,
        );
        setIsRefreshing(false);
    }, [requestState, fetchTripHistory, id]);

    const columnsWithClassName = columns.map(column => {
        if (column.key === 'trip_id') {
            return { ...column, className: 'trip-id-cell' };
        } else if (column.key === 'action') {
            return { ...column, className: 'action-cell' };
        }
        return column;
    });
    const formatStatus = (trip: any) => {
        return (
            <div>
                {trip?.status == 'complete' && (
                    <Tag color="green" className="uppercase">
                        {trip.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
                {(trip?.status == 'finding_driver' ||
                    trip?.status == 'driver_otw_pickup' ||
                    trip?.status == 'driver_arrived_pickup' ||
                    trip?.status == 'driver_otw_destination' ||
                    trip?.status == 'driver_arrived_destination') && (
                    <Tag color="geekblue" className="uppercase">
                        {trip?.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
                {trip?.status?.indexOf('driver_not_found') > -1 && (
                    <Tag color="volcano" className="uppercase">
                        {trip?.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
                {trip?.status?.indexOf('canceled') > -1 && (
                    <Tag color="red" className="uppercase">
                        {trip?.status?.replaceAll('_', ' ')}
                    </Tag>
                )}
            </div>
        );
    };

    return (
        <div>
            <ListLayout
                title="Trips History"
                searchComponent={
                    <DriverTripHistoryFilter
                        onFilterSubmit={onFilterSubmit}
                        loading={loading && pagination.current == 1}
                    />
                }
                actionComponent={
                    <div>
                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', marginRight: '16px' }}>
                            Please refresh to obtain the latest status of ongoing trips
                        </span>
                        <Button onClick={onRefresh} type="primary">
                            Refresh
                        </Button>
                    </div>
                }
            >
                <Table
                    className="custom-table-hover"
                    loading={loading}
                    columns={columnsWithClassName}
                    onRow={record => ({
                        onClick: event => {
                            const target = event.target as HTMLElement;
                            const isRestrictedCell = target.closest('.trip-id-cell') || target.closest('.action-cell');
                            if (!isRestrictedCell) {
                                navigate(`/trips/${record.trip_id}`);
                            }
                        },
                        style: { cursor: 'pointer' },
                    })}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        pageSizeOptions: ['10', '20', '50'],
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total: any) => `${total?.toLocaleString()} items`,
                    }}
                    onChange={onTableChange}
                />
            </ListLayout>

            <style>{`
                .custom-table-hover .ant-table-tbody > tr:hover > td {
                    background-color: #e6f7ff !important;
                    cursor: pointer;
                }
            `}</style>
        </div>
    );
};

export default DriverTripHistory;
