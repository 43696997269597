import React, { useState } from 'react';
import { Select } from 'antd/lib';
import Text from 'components/basic/Typography/Text';
import { Button, Input } from 'components/basic';
const { Option } = Select;
interface OperatorFilterProps {
    onFilterSubmit: (values: { field: string; keyword: any; account_status: string; user_role: string }) => void;
    onFilterReset?: () => void;
    setPagination?: any;
    loading: boolean | undefined;
}

const OperatorFilter: React.FC<OperatorFilterProps> = ({ onFilterSubmit, onFilterReset, setPagination, loading }) => {
    const [selectedField, setSelectedField] = useState('first_name');
    const [searchKeyword, setSearchKeyword] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('');

    const handleFieldChange = (value: string) => {
        setSelectedField(value);
    };

    const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(event.target.value);
    };

    const handleFilterSubmit = () => {
        let kw = searchKeyword;
        if (onFilterSubmit) {
            onFilterSubmit({
                field: selectedField,
                keyword: kw,
                account_status: selectedStatus,
                user_role: selectedRole,
            });
        }
    };

    const handleFilterReset = () => {
        setSelectedField('first_name');
        setSelectedStatus('');
        setSearchKeyword('');
        onFilterSubmit({
            field: 'first_name',
            account_status: '',
            keyword: '',
            user_role: '',
        });
    };

    return (
        <div className="flex items-start gap-2">
            <div className="flex items-start gap-2">
                <div className="block w-full">
                    <Text type="label">Search by name or email</Text>
                    <div className="flex gap-1 w-96">
                        <Select value={selectedField} className="w-1/2" onChange={handleFieldChange}>
                            <Option value="first_name">First Name</Option>
                            <Option value="last_name">Last Name</Option>
                            <Option value="email">Email</Option>
                        </Select>
                        <Input
                            className="rounded-full p-lg"
                            value={searchKeyword}
                            onChange={handleKeywordChange}
                            placeholder="Enter search keyword..."
                            onPressEnter={handleFilterSubmit}
                        />
                    </div>
                </div>
                <div className="block">
                    <Text type="label">Role</Text>
                    <Select className="w-52" defaultActiveFirstOption value={selectedRole} onChange={setSelectedRole}>
                        <Option value="">All</Option>
                        <Option value="SUPER_ADMIN">Super Admin</Option>
                        <Option value="OPERATOR">Operator</Option>
                        <Option value="FINANCE">Finance</Option>
                        <Option value="FINANCE_MANAGER">Finance Manager</Option>
                        <Option value="CITY_MANAGER">City Manager</Option>
                        <Option value="PRODUCT_OWNER">Product Owner</Option>
                        <Option value="TRANSPORT_OPS">Transport Ops</Option>
                        <Option value="IT_OPS">IT Ops</Option>
                        <Option value="QUALITY_ASSURANCE">Quality Assurance</Option>
                        <Option value="SERVICE_EXCELLENT">Service Excellence</Option>
                        <Option value="MARKETING">Marketing</Option>
                        <Option value="ONBOARDING">Transport Ops - Onboarding Team</Option>
                        <Option value="DREX">Transport Ops - DREX Team</Option>
                        <Option value="OPS_STRATEGY_1">Transport Ops - Strategy 1</Option>
                        <Option value="OPS_STRATEGY_2">Transport Ops - Strategy 2</Option>
                        <Option value="EXPANSION">Transport Ops - Expansion</Option>
                        <Option value="SERVICE_EXCELLENT_AGENT">SE Agent</Option>
                        <Option value="SERVICE_EXCELLENT_SUPPORT">SE Support</Option>
                        <Option value="VISMIN_ONBOARDING">VisMin Onboarding</Option>
                        <Option value="ONBOARDING_MANAGER">Onboarding Manager</Option>
                    </Select>
                </div>
                <div className="block">
                    <Text type="label">Status</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedStatus}
                        onChange={setSelectedStatus}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'ACTIVE', label: 'Active' },
                            { value: 'DEACTIVATED', label: 'Deactivated' },
                        ]}
                    />
                </div>
            </div>
            <div className="flex items-center gap-1 mt-lg">
                <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default OperatorFilter;
