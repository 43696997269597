import LottiePlayer from 'lottie-react';
import LoginLayout from 'components/layouts/LoginLayout/LoginLayout';
import React from 'react';
import styles from './LoginErrorPage.module.scss';
import classnames from 'clsx';
import { useNavigate } from 'react-router-dom';
import errorAnimation from './modal-sorry.json';
import Text from 'components/basic/Typography/Text';

const LoginErrorPage: React.FC = () => {
    const navigate = useNavigate();  
    const handleLogin = async () => {
        navigate("/");
    };

    return (<LoginLayout 
        component={
            <div className={classnames(styles.RectangleCard)} >
                <LottiePlayer 
                    animationData={errorAnimation} 
                    loop
                    autoplay
                    style={{ width: '250px', height: '250px' }}
                />
                <div className='my-lg'>
                    <Text size='text-md lg:text-lg 2xl:text-xl' fontWeight='font-bold' className='leading-none text-center mb-2'>
                        Oops! Looks like you don’t have permission yet.
                    </Text>
                    <Text size='text-sm 2xl:text-md' className='leading-none text-center mb-md' color='text-black-lighter'>
                        Please contact the Superadmin to give you access.
                    </Text>
                </div>
                <div className={classnames(styles.RetryButton)} onClick={handleLogin}>
                    Retry Logging in
                </div>
            </div>
        }
    />);
}
 
export default LoginErrorPage;
