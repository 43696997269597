import React, { useCallback, useState } from 'react';
import styles from './DashboardLayout.module.scss';
import { LogoutButton, Text } from 'components';
import classnames from 'clsx';
import { Menu, MenuProps, Dropdown, Space, Layout, theme, message, Skeleton, notification } from 'antd/lib';
import useMount from 'hooks/useMount';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import {
    UserOutlined,
    BellOutlined,
    BranchesOutlined,
    CarOutlined,
    DashboardOutlined,
    SettingOutlined,
    WalletOutlined,
    AuditOutlined,
    MoneyCollectOutlined,
    UsergroupAddOutlined,
    FundViewOutlined,
    TransactionOutlined,
} from '@ant-design/icons';
import Footer from '../Footer/Footer';
const { Header, Content, Sider } = Layout;
import processingBtn from './../../basic/Table/btn-processing-blue-mc.json';
import LottiePlayer from 'lottie-react';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const DashboardLayout = (
    props: any & {
        children?: React.ReactNode;
    } & {
        ref?: React.Ref<HTMLDivElement> | undefined;
    },
) => {
    const { user, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);

    const [claims, setClaims] = React.useState<any>(null);

    useMount(() => {
        if (!props.loading) {
            const detectUser = async () => {
                const accessToken = await getAccessTokenSilently();
                if (accessToken) {
                    getIdTokenClaims().then(claims => {
                        setClaims(claims);
                    });
                }
            };
            detectUser();
        }
    });

    const isYourAccountWorthy = () => {
        return (
            user?.email === 'russell.gutierrez@angkas.com' ||
            user?.email === 'marjorie.araneta@angkas.com' ||
            user?.email === 'arrianelivara_xt@angkas.com' ||
            user?.email === 'janeumir.batacan@angkas.com' ||
            user?.email === 'jaywardhan.raghuvanshi_xt@angkas.com'
        );
    };

    const copyAccessToken = useCallback(async () => {
        const accessToken = await getAccessTokenSilently();
        navigator.clipboard.writeText(accessToken);
        message.success('Access Token copied to clipboard');
    }, [getAccessTokenSilently]);

    const items: MenuProps['items'] = [
        isYourAccountWorthy()
            ? {
                  key: '2',
                  label: (
                      <button
                          className="bg-inherit border-transparent w-full text-left cursor-pointer"
                          onClick={copyAccessToken}
                      >
                          <span className="block">Copy Your Access Token</span>
                          <span className="text-xs text-red-400 block">Do not share this with anyone else!</span>
                      </button>
                  ),
              }
            : null,
        {
            key: '1',
            label: <LogoutButton />,
        },
    ];

    const hasViewAccessibility = (page: string) => {
        return (
            getPermissions(claims?.user_role, page)?.includes(Accessibility.VIEW) ||
            getPermissions(claims?.user_role, page)?.includes(Accessibility.ALL) ||
            getPermissions(claims?.user_role, page)?.includes(Accessibility.EDIT)
        );
    };

    const sidebarItems = [
        {
            label: 'Home',
            key: '/',
            path: '/',
            icon: <DashboardOutlined />,
        },
        {
            label: !collapsed ? 'User Management' : '',
            key: 'users',
            type: 'group',
            children: [
                {
                    label: 'Drivers',
                    key: 'drivers',
                    path: '/drivers',
                    icon: <CarOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.DRIVERS),
                },
                {
                    label: 'Customers',
                    key: 'customers',
                    path: '/customers',
                    icon: <UserOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.CUSTOMERS),
                },
                // {
                //     label: 'VIP Customers',
                //     key: 'vip-customers',
                //     path: '/vip-customers',
                //     icon: <StarOutlined />,
                // },
            ],
        },
        {
            label: !collapsed ? 'Trip Management' : '',
            key: 'trips',
            type: 'group',
            children: [
                {
                    label: 'Trips',
                    key: 'trips',
                    path: '/trips',
                    icon: <BellOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.TRIPS),
                },
                {
                    label: 'Zones',
                    key: 'zones',
                    path: '/zones',
                    icon: <BranchesOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.ZONES),
                },
            ],
        },

        {
            label: !collapsed ? 'Finance' : '',
            key: 'finance',
            type: 'group',
            children: [
                {
                    label: 'Wallet History',
                    key: 'transactions',
                    path: '/transactions',
                    icon: <WalletOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.WALLET_HISTORY),
                },
                {
                    label: 'Installments',
                    key: 'installments',
                    path: '/customers',
                    icon: <MoneyCollectOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.INSTALLMENT),
                },
                {
                    label: 'Incentives',
                    key: 'incentives',
                    path: '/incentives',
                    icon: <FundViewOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.INCENTIVES),
                },
                // {
                //     label: 'Vouchers',
                //     key: 'vouchers',
                //     path: '/vouchers',
                //     disabled: claims?.user_role !== 'SUPER_ADMIN',
                //     icon: <PercentageOutlined />,
                // },
                {
                    label: 'Bulk Top Ups/Deductions',
                    key: 'bulk-top-up-deductions',
                    path: '/bulk-top-up-deductions',
                    icon: <TransactionOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.TOP_UP_AND_DEDUCTIONS),
                },
                {
                    label: <Text onClick={() => navigate('/gcash-transaction')}>GCash Transactions</Text>,
                    key: 'gcash-transaction',
                    icon: <img src="/images/gcash.svg" onClick={() => navigate('/gcash-transaction')} />,
                    disabled: !hasViewAccessibility(PagesEnum.GCASH_TRANSACTIONS),
                    type: 'divider',
                    children: [
                        {
                            label: 'Customer Transactions',
                            key: 'customer-transactions',
                        },
                        {
                            label: 'Drivers Transactions',
                            key: 'driver-transactions',
                        },
                    ],
                },
            ],
        },
        {
            label: !collapsed ? 'Settings' : '',
            key: 'settings',
            type: 'group',
            children: [
                {
                    label: 'General Settings',
                    key: 'settings',
                    path: '/settings',
                    icon: <SettingOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.GENERAL_SETTINGS),
                },
                {
                    label: 'Operators',
                    key: 'operators',
                    path: '/operators',
                    icon: <UsergroupAddOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.OPERATORS),
                },
                {
                    label: 'Logs',
                    key: 'logs',
                    path: '/logs',
                    icon: <AuditOutlined />,
                    disabled: !hasViewAccessibility(PagesEnum.LOGS),
                },
            ],
        },
    ];

    const onClickSidebar = (e: any) => {
        let path = e.keyPath.join('/');
        navigate(path);
    };

    const getEnvironmentLabel = () => {
        const env = process.env.REACT_APP_ENVIRONMENT;

        switch (env) {
            case 'prod':
                return 'PRODUCTION';
            case 'uat':
                return 'UAT';
            case 'test':
                return 'TEST';
            case 'development':
            case 'dev':
                return 'DEV';
            default:
                return 'UNKNOWN';
        }
    };

    return (
        <Layout
            className={classnames(
                styles.DashboardLayout,
                'main-layout w-screen h-screen flex flex-col overflow-hidden overflow-x-auto',
            )}
        >
            <Sider
                className="flex flex-col"
                theme="light"
                collapsible={!props.loading}
                collapsed={collapsed}
                onCollapse={value => setCollapsed(value)}
            >
                {props.loading ? (
                    <>
                        <div className="w-full h-24 flex items-center flex-row">
                            <LottiePlayer
                                animationData={processingBtn}
                                loop
                                autoplay
                                style={{ width: '100%', height: '50px' }}
                            />
                        </div>
                        <Skeleton loading={props.loading} active className="px-4" />
                        <Skeleton loading={props.loading} active className="px-4" />
                        <Skeleton loading={props.loading} active className="px-4" />
                        <Skeleton loading={props.loading} active className="px-4" />
                    </>
                ) : (
                    <>
                        <Header
                            style={{
                                padding: 0,
                                marginBottom: '2rem',
                                background: 'white',
                            }}
                        >
                            <div className="py-4 px-8 mt-10 mb-4">
                                {!collapsed && (
                                    <img
                                        src="https://uploads-ssl.webflow.com/64471d61bdefef433f4dd90c/64471d8bc62d862756262513_Logo.svg"
                                        style={{ width: '100%' }}
                                    />
                                )}
                            </div>
                        </Header>
                        <Content className="h-5/6 flex flex-col justify-between" style={{ overflowX: 'hidden' }}>
                            <Menu
                                theme="light"
                                defaultSelectedKeys={['1']}
                                mode="inline"
                                items={sidebarItems}
                                onClick={onClickSidebar}
                            />
                        </Content>
                    </>
                )}
            </Sider>
            <div className="flex-1 flex flex-row" style={{ borderLeft: 'solid 1px #efefef' }}>
                <div className="flex-1 flex-flex-col h-full overflow-y-auto flex flex-col gap-4">
                    <div className="px-8 py-4 border-t-0 border-l-0 border-r-0 border-b-1 border-slate-50 border-dashed flex items-center">
                        <div className="flex-1 flex items-center gap-4">
                            <h1 className="text-md font-semibold m-0">Angkas Admin Portal</h1>
                            <span className="text-sm font-light text-gray">{getEnvironmentLabel()}</span>
                        </div>
                        <div>
                            {props.loading && <Skeleton.Avatar active size="large" />}

                            {!props.loading && (
                                <Dropdown menu={{ items }}>
                                    <a onClick={e => e.preventDefault()}>
                                        <Space>
                                            {user && (
                                                <div className="flex items-center py-2 px-3 bg-slate-50 rounded-3xl hover:bg-slate-100 transition">
                                                    <img src={user.picture} className="rounded-full w-8 h-8" />
                                                    <div>
                                                        <p className="text-gray-500 mb-0 mx-2 leading-snug text-sm">
                                                            {user.name}
                                                        </p>
                                                        <p className="text-gray-700 mb-0 mx-2 leading-snug">
                                                            {claims?.user_role}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </Space>
                                    </a>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                    <div className="px-8 flex-1">{props.children}</div>
                    <Footer loading={props.loading} />
                </div>
            </div>
        </Layout>
    );
};

export default DashboardLayout;
