import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';
import LoginErrorPage from './LoginErrorPage/LoginErrorPage';

const CallbackPage = () => {
    const { error } = useAuth0();

    if (error) {
        return (
            <LoginErrorPage />
        );
    }

    return (
        <div className="page-layout">
            <div className="page-layout__content" />
        </div>
    );
};

export default CallbackPage;