import Modal from 'antd/lib/modal/Modal';
import { CustomNotification } from 'components/basic';
import useApi from 'hooks/useApi';
import useMount from 'hooks/useMount';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getLayerById, deleteLayer } from 'services/message.service';
interface DeleteLayerModalProps {
    modal: any;
    onSuccess?: () => void;
    layerId?: any;
    name?: any;
}

const DeleteLayerModal: React.FC<DeleteLayerModalProps> = ({ modal, onSuccess, layerId, name }) => {
    let { id: paramId } = useParams();
    const [layerName, setLayerName] = useState('');
    const idToDelete = layerId ?? paramId;
    const { request } = useApi({
        api: getLayerById,
    });
    useMount(() => {
        if (!layerId) {
            fetchLayerById(layerId + '');
        }
    });
    const fetchLayerById = useCallback(
        async (layerId: string) => {
            try {
                if (layerId === 'null') return;

                const response = await request({ id: layerId });
                setLayerName(response?.data?.name);
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    const handleOnClose = () => {
        modal.close();
    };

    const { request: deleteRequest, loading } = useApi({
        api: deleteLayer,
    });

    const deleteLayers = async (id: any) => {
        const res = await deleteRequest({
            id,
        });
        if (!res?.error) {
            CustomNotification({
                type: 'success',
                message: 'Success',
                description: <p>{layerName} Layers deleted</p>,
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: res?.error?.message,
            });
        }
    };

    const handleFormFinish = async () => {
        if (idToDelete) {
            deleteLayers(idToDelete);
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'No valid ID provided for deletion.',
            });
        }
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Delete Layer"
            onOk={handleFormFinish}
            okText="Delete"
            okButtonProps={{
                danger: true,
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <p>
                Are you sure you want to delete the layer "<b>{layerName ? layerName : name}</b>" ? This action cannot
                be undone.
            </p>
        </Modal>
    );
};

export default DeleteLayerModal;
