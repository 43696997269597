import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useFilter from 'hooks/useFilter';
import useApi from 'hooks/useApi';
import { awardIncentives } from 'services/message.service';
import { downloadCSVFile } from 'services/file.service';
import papa from 'papaparse';
import { json2csv } from 'json-2-csv';
import { useNavigate } from 'react-router-dom';
import { Alert, message, notification } from 'antd/lib';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';
import { AuthUserContext } from 'components/context/AuthUserContext';

const UploadIncentivePage = () => {
    const [showDownloadBtn, setShowDownloadBtn] = useState(false);
    const [tableData, setTableData]: any = useState([]);
    const [erroNumber, setErrorNumber] = useState(0);
    const [csv, setCsv] = useState('');
    const [successNumber, setSuccessNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const defaultBatchSize = 10;
    const sleepPerBatchMS = 1000;
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const { requestState } = useFilter({
        search_by: '',
        search_key: '',
        driver_id: '',
        user_id: '',
        limit: 10,
    });

    const { request: awardIncentivesRequest } = useApi({
        api: awardIncentives,
    });

    const uploadCsv = (csvResult: []) => {
        uploadCsvData(csvResult);
    };

    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

    const sliceIntoChunks = (arr: any[], chunkSize: number) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    };

    const uploadCsvData = useCallback(
        async (csvResult: []) => {
            setLoading(true);
            notification.info({
                key: 'progress',
                message: 'Uploading',
                description: '0% completed',
            });

            let chunks = sliceIntoChunks(csvResult, defaultBatchSize);
            const result: any[] = [];
            let validationError = false;
            const failedRecords: any[] = [];
            try {
                for (let i = 0; i < chunks.length; i++) {
                    await Promise.all(
                        chunks[i].map(async (res: any) => {
                            const apiRes = await awardIncentivesRequest({ token, res });
                            if (apiRes.error) {
                                if (apiRes.error.errorCode === 'VALIDATION_ERROR') {
                                    validationError = true;
                                } else {
                                    failedRecords.push({
                                        generated_at: res?.generated_at,
                                        batch_id: res?.batch_id,
                                        entry_id: res?.entry_id,
                                        incentive_campaign_id: res?.incentive_campaign_id,
                                        driver_id: res?.driver_id,
                                        amount: res?.amount,
                                        metadata: res?.metadata,
                                        failed_response: `${apiRes.error.message}` + ',' + `status : ${apiRes.status}`,
                                    });
                                }
                            } else {
                                result.push(apiRes);
                            }
                        }),
                    );
                    // wait for 1s
                    await delay(sleepPerBatchMS);
                    notification.info({
                        key: 'progress',
                        message: 'Uploading',
                        description:
                            Number((i + 1) / chunks.length).toLocaleString(undefined, {
                                style: 'percent',
                                minimumFractionDigits: 2,
                            }) + ' completed',
                    });
                }
                notification.destroy();
                if (validationError) {
                    message.error('Please upload a CSV file with a valid template.');
                } else if (failedRecords.length == 0) {
                    notification.success({
                        message: 'Success',
                        description: 'All rows are successfully uploaded',
                    });

                    navigate('/incentives');
                    return;
                } else if (result.length > 0) {
                    notification.warning({
                        message: 'Error',
                        description: 'Some rows encountered error',
                        duration: 3,
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: 'Error for all rows',
                        duration: 3,
                    });
                }

                setErrorNumber(failedRecords.length);
                setShowDownloadBtn(true);
                setSuccessNumber(result.length);
                setTableData(failedRecords);

                const csvData = await json2csv(failedRecords);
                setCsv(csvData);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [token, awardIncentivesRequest, navigate],
    );

    const downloadFailedData = () => {
        const csvData = String(csv);
        downloadCSVFile(csvData, 'failed_incentives');
    };

    const downloadTemplate = () => {
        let tempData = `generated_at,batch_id,entry_id,incentive_campaign_id,driver_id,amount,metadata
2023-08-16T17:34:10.111+08:00,01c17c66-458a-4b03-bbed-7aa6093ede4a,eddcfa15-e386-4a40-b233-ef2c6bd6538d,RUSSELL2023,2a155454-d457-4f93-b8ec-8424edaff6f3,30,"other-info,some more info"
2023-08-16T17:34:10.111+08:00,01c17c66-458a-4b03-bbed-7aa6093ede4a,eddcfa15-e386-4a40-b233-ef2c6bd6538d,RUSSELL2023,2a155454-d457-4f93-b8ec-8424edaff6f3,30,"other-info,some more info"`;

        downloadCSVFile(tempData, 'incentives_template_v1');
    };

    const handleFile = (event: any) => {
        setShowDownloadBtn(false);
        papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result: any) {
                const max_rows = 3000;
                if (result.data.length > max_rows) {
                    // Maximum of 3000 rows per upload
                    notification.error({
                        message: 'Error',
                        description: `Maximum of ${max_rows} Rows per upload`,
                        duration: 3,
                    });
                    return;
                }

                let arrayObj = result.data.map((d: any) => {
                    return {
                        generated_at: d.generated_at,
                        batch_id: d.batch_id,
                        entry_id: d.entry_id,
                        incentive_campaign_id: d.incentive_campaign_id,
                        driver_id: d.driver_id,
                        amount: Number(d.amount),
                        number_of_completed_trips: d.number_of_completed_trips,
                        metadata: d.metadata,
                    };
                });
                uploadCsv(arrayObj);
            },
        });
    };
    const handleFileUpload = (event: any) => {
        (hiddenFileInput?.current as any)?.click();
    };
    const columns = [
        {
            title: 'Generated At',
            key: 'generated_at',
            render: (incentive: any) => incentive?.generated_at,
        },
        {
            title: 'Batch Id',
            key: 'batch_id',
            render: (incentive: any) => incentive?.batch_id,
        },
        {
            title: 'Entry Id',
            key: 'entry_id',
            render: (incentive: any) => incentive?.entry_id,
        },
        {
            title: 'Incentive Campaign Id',
            key: 'incentive_campaign_id',
            render: (incentive: any) => (
                <div className="max-w-[200px]">
                    <Text>{incentive.incentive_campaign_id || '-'}</Text>
                </div>
            ),
        },
        {
            title: 'Driver Id',
            key: 'driver_id',
            render: (incentive: any) => incentive?.driver_id,
        },
        {
            title: 'Amount',
            key: 'amount',
            render: (incentive: any) => `PHP ${incentive.amount.toFixed(2)}`,
        },
        {
            title: 'Metadata',
            key: 'metadata',
            render: (incentive: any) => incentive?.metadata,
        },
        {
            title: 'Failed_Message',
            key: 'failed_response',
            render: (incentive: any) => incentive?.failed_response,
        },
    ];

    return (
        <ListLayout goBackLink="/incentives" title={'Create New Incentives'}>
            <div className="flex justify-between">
                <Alert
                    showIcon
                    type="warning"
                    message="Please make sure you follow the correct format before uploading the
                    CSV file."
                />
                <div className="flex flex-row gap-1">
                    {hasActionAccess(PagesEnum.INCENTIVES) && (
                        <div className="flex flex-col">
                            <div>
                                <input
                                    hidden
                                    id="upload-file"
                                    type="file"
                                    accept=".csv"
                                    name="file"
                                    ref={hiddenFileInput}
                                    onChange={handleFile}
                                ></input>
                                <Button type="primary" onClick={handleFileUpload} icon={<UploadOutlined />}>
                                    Upload CSV
                                </Button>
                            </div>
                        </div>
                    )}
                    <div>
                        <Button
                            type="ghost"
                            target="_blank"
                            onClick={() => downloadTemplate()}
                            icon={<DownloadOutlined />}
                        >
                            Download Template
                        </Button>
                    </div>
                </div>
            </div>
            {showDownloadBtn ? (
                <div style={{ border: '1px solid #ADB6B9' }} className="flex justify-between items-center m-2 p-4">
                    <div>
                        <ul className="text-sm font-bold p-0">
                            Upload .csv error
                            <li className="block font-medium text-base">{erroNumber} Number of rows have error </li>
                            <li className="block font-medium text-base">{successNumber} Number of rows have success</li>
                        </ul>
                    </div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                border: '1px solid #D9D9D9',
                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                            }}
                            className="bg-inherit px-4 py-2"
                            onClick={() => downloadFailedData()}
                            id="text-button"
                        >
                            Download Failed Rows
                        </button>
                    </div>
                </div>
            ) : null}
            <Table
                columns={columns}
                loading={loading}
                // pagination={false}
                dataSource={tableData}
            />
        </ListLayout>
    );
};

export default UploadIncentivePage;
