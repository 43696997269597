import React, { MouseEvent } from 'react';
import { CardProps } from './CardProps';
import classnames from 'classnames';
import Text from '../Typography/Text';

const Card = (props: CardProps & {
  children?: React.ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  disabled?: boolean;
}) => {
  const handleCardClick = (event: MouseEvent<HTMLDivElement>) => {
    if (props.onClick && !props.disabled) {
      props.onClick(event);
    }
  };

  const cardClassName = classnames(
    props.className,
    props.style === 'dashed'
      ? 'border-dashed border-1 border border-gray-300 items-center flex justify-center'
      : 'bg-white',
    props.disabled ? 'opacity-50 cursor-not-allowed' : '', // Apply styles for disabled
    'flex flex-col gap-2'
  );

  return (
    <div className={cardClassName} onClick={handleCardClick}>
      {props.title && (
        <Text type="title-small" color="text-gray uppercase">
          {props.title}
        </Text>
      )}

      {props.children}
    </div>
  );
};

export default Card;
