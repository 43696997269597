import React, { useCallback, useState } from 'react';
import { Select } from 'antd/lib';
import Text from 'components/basic/Typography/Text';
import { Button } from 'components/basic';
import { getDriversList } from 'services/message.service';
import useApi from 'hooks/useApi';
const { Option } = Select;

interface TransactionFilterProps {
    onFilterSubmit?: (values: { user_id: string; account_type: string }) => void;
    onFilterReset?: () => void;
    setPagination?: any;
    loading: boolean | undefined;
}

const TransactionFilter: React.FC<TransactionFilterProps> = ({ onFilterSubmit, onFilterReset, loading }) => {
    // const [userId, setUserId] = useState('');
    const [selectedAccountType, setSelectedAccountType] = useState('');
    const [selectedDriverId, setSelectedDriverId] = useState('');
    const [data, setData] = useState([{ value: '', label: 'All Drivers' }]);

    const { request: getDriversRequest, loading: fetchingDrivers } = useApi({
        api: getDriversList,
    });

    const handleFilterSubmit = () => {
        if (onFilterSubmit) {
            onFilterSubmit({
                user_id: selectedDriverId,
                account_type: selectedAccountType,
            });
        }
    };

    const handleFilterReset = () => {
        setSelectedAccountType('');
        setSelectedDriverId('');
        if (onFilterSubmit) {
            onFilterSubmit({ user_id: '', account_type: '' });
        }
        onFilterReset && onFilterReset();
    };

    const handleDriverIdChanged = useCallback(
        (value: any) => {
            setSelectedDriverId(value);
        },
        [setSelectedDriverId],
    );

    const fetchDrivers = useCallback(
        async (searchKey: '') => {
            try {
                const result = await getDriversRequest({
                    page_size: 100,
                    page: 1,
                    search_by: 'first_name',
                    search_key: searchKey,
                });
                let d = result.data.data;
                let driver = d.drivers.map((c: any) => {
                    return {
                        ...c,
                        key: c.id,
                    };
                });
                let options = [{ value: '', label: 'All Drivers' }];
                driver.forEach(function (d: any) {
                    options.push({
                        value: d.id,
                        label: d.first_name + ' ' + d.last_name,
                    });
                });
                setData(options);
            } catch (error) {
                throw error;
            }
        },
        [getDriversRequest],
    );

    const handleSearchDriver = useCallback(
        (value: any) => {
            if (value != '') {
                fetchDrivers(value);
            }
        },
        [fetchDrivers],
    );

    return (
        <div className="flex items-end gap-2">
            <div className="flex items-center gap-2">
                <div className="block w-full">
                    <Text type="label">Search by Driver</Text>
                    <div className="flex gap-1">
                        <Select
                            loading={fetchingDrivers}
                            className="w-56"
                            showSearch
                            optionFilterProp="children"
                            placeholder="Select Driver"
                            onChange={handleDriverIdChanged}
                            onSearch={handleSearchDriver}
                            filterOption={(input, option: any) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={data}
                            defaultValue={data[0].value}
                            value={selectedDriverId}
                        />
                    </div>
                </div>
                <div className="block">
                    <Text type="label">Wallet Type</Text>
                    <Select
                        className="w-24"
                        defaultActiveFirstOption
                        value={selectedAccountType}
                        onChange={setSelectedAccountType}
                        options={[
                            { value: '', label: 'All' },
                            { value: 'CASH', label: 'Cash Wallet' },
                            { value: 'CREDIT', label: 'Angkas Wallet' },
                        ]}
                    />
                </div>
            </div>
            <div className="flex items-center gap-1">
                <Button type="primary" ghost onClick={handleFilterSubmit} loading={loading} disabled={loading}>
                    Apply Filter
                </Button>
                <Button onClick={handleFilterReset} disabled={loading}>
                    Clear Filters
                </Button>
            </div>
        </div>
    );
};

export default TransactionFilter;
