import { Tag } from 'antd/lib';
import { Text } from 'components/basic';
import React from 'react';

const renderEmail = (user: any) => {
    if (user.email) {
        return <a href={`mailto:${user.email}`}>{user.email}</a>;
    } else {
        return <p className="m-0 text-gray-500">No Email</p>;
    }
};

const userRoleColor = (user: any) => {
    switch (user.user_role) {
        case 'SUPER_ADMIN':
            return 'geekblue';
        case 'FINANCE':
            return 'volcano';
        case 'FINANCE_MANAGER':
            return 'volcano';
        case 'CITY_MANAGER':
            return 'blue';
        case 'OPERATOR':
            return 'gold';
        case 'PRODUCT_OWNER':
            return 'orange';
        case 'TRANSPORT_OPS':
            return 'lime';
        case 'IT_OPS':
            return 'green';
        case 'QUALITY_ASSURANCE':
            return 'cyan';
        case 'SERVICE_EXCELLENT':
            return 'blue';
        case 'MARKETING':
            return 'purple';
        case 'ONBOARDING':
            return 'geekblue';
        case 'DREX':
            return 'volcano';
        case 'OPS_STRATEGY_1':
            return 'gold';
        case 'OPS_STRATEGY_2':
            return 'orange';
        case 'EXPANSION':
            return 'purple';
        case 'SERVICE_EXCELLENT_AGENT':
            return 'lime';
        case 'SERVICE_EXCELLENT_SUPPORT':
            return 'green';
        case 'VISMIN_ONBOARDING':
            return 'gold';
        case 'ONBOARDING_MANAGER':
            return 'orange';
        default:
            return ''; // Or any default color
    }
};

export const columns = [
    {
        title: 'First Name',
        key: 'first_name',
        dataIndex: 'first_name',
    },
    {
        title: 'Last Name',
        key: 'last_name',
        dataIndex: 'last_name',
    },
    {
        title: 'Email',
        width: '20%',
        render: (user: any) => renderEmail(user),
    },
    {
        title: 'Role',
        render: (user: any) => (
            <div className="flex items-center">
                <Tag
                    color={userRoleColor(user)}
                    style={{
                        width: 'fit-content',
                        fontFamily: '"Source Sans 3", sans-serif !important',
                        // paddingRight: '20px',
                    }}
                >
                    <Text className="text-inherit uppercase">{user.user_role}</Text>
                </Tag>
            </div>
        ),
    },
    {
        title: 'Internal ID',
        width: '30%',
        render: (user: any) => (
            <div className="flex items-center">
                <Text type="uuid">{user.id}</Text>
            </div>
        ),
    },
    {
        title: 'Status',
        render: (user: any) =>
            user.status === 'active' || user.status === 'ACTIVE' ? (
                <Tag color="green">Active</Tag>
            ) : (
                <Tag>
                    <Text color="text-gray">Deactivated</Text>
                </Tag>
            ),
    },
];

export const exportColumns = [
    {
        title: 'First Name',
        key: 'first_name',
        dataIndex: 'first_name',
    },
    {
        title: 'Last Name',
        key: 'last_name',
        dataIndex: 'last_name',
    },
    {
        title: 'Email',
        width: '20%',
        render: (user: any) => renderEmail(user),
    },
    {
        title: 'Role',
        width: '18%',
        render: (user: any) => (
            <div className="flex items-center">
                <Tag
                    color={userRoleColor(user)}
                    style={{
                        width: 'fit-content',
                        fontFamily: '"Source Sans 3", sans-serif !important',
                        paddingRight: '1rem',
                        paddingLeft: '0.5rem',
                    }}
                >
                    <Text className="text-inherit text-sm">{user.user_role}</Text>
                </Tag>
            </div>
        ),
    },
    {
        title: 'Internal ID',
        width: '25%',
        render: (user: any) => (
            <div className="flex items-center">
                <Text type="uuid" size="text-sm pr-[1.65rem]">
                    {user.id}
                </Text>
            </div>
        ),
    },
    {
        title: 'Status',
        render: (user: any) =>
            user.status === 'active' || user.status === 'ACTIVE' ? (
                <Tag color="green">Active</Tag>
            ) : (
                <Tag>
                    <Text color="text-gray pr-[0.25rem]">Deactivated</Text>
                </Tag>
            ),
    },
];
