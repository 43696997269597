import React, { useCallback, useState } from 'react';
import { Spin, Tabs } from 'antd/lib';
import { Card } from 'components';
import { getCustomer } from 'services/message.service';
import useMount from 'hooks/useMount';
import { useParams } from 'react-router-dom';
import Text from 'components/basic/Typography/Text';
import useApi from 'hooks/useApi';
import DetailContentLayout from 'components/layouts/ContentLayout/DetailContentLayout';
import CustomerOverview from './CustomerOverview/CustomerOverview';
import CustomerSummary from './CustomerSummary';
import classnames from 'classnames';
import { BulbOutlined, FileSearchOutlined } from '@ant-design/icons';
import CustomerTripHistory from '../CustomerTripHistory/CustomerTripHistory';
import { useNavigate } from 'react-router-dom';

const CustomerDetailedPage = ({ isVip = false, page = 'overview' }: { isVip: boolean; page: string }) => {
    const [expanded, setExpanded] = useState(page === 'overview');
    const [currentPage, setCurrentPage] = useState(page);
    let { id } = useParams();
    const navigate = useNavigate();

    useMount(() => {
        fetchCustomer(id);
    });

    const { request, result, loading } = useApi({
        api: getCustomer,
    });

    const fetchCustomer = useCallback(
        async (id: string | undefined) => {
            try {
                await request({ id });
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    if (loading) {
        return (
            <Card>
                <Spin />
            </Card>
        );
    }
    const data = result?.data || {};

    return (
        <DetailContentLayout fallbackRoute={isVip ? '/vip-customers' : '/customers'} goBackLink={isVip ? '/vip-customers' : '/customers'}>
            <div className={classnames('flex mt-4 transition-all ease-in-out', expanded ? 'gap-4' : '')}>
                <div
                    className={classnames(
                        'bg-white rounded border border-dashed border-slate-200 flex flex-col gap-2 transition-all ease-in-out',
                        expanded ? 'w-80 p-lg opacity-1 p-lg' : 'w-0 p-0 opacity-0',
                    )}
                >
                    <CustomerSummary
                        data={data}
                        page="customer"
                        refreshData={() => request({ id })}
                        expanded={expanded}
                    />
                </div>
                <div
                    className={classnames('flex-1 driver-profile-right transition-all ease-in-out', expanded ? '' : '')}
                >
                    <div
                        className={classnames(
                            'bg-white rounded border border-dashed border-slate-200 flex flex-col gap-2 transition-all ease-in-out w-full',
                            expanded ? 'h-0 opacity-0' : 'h-auto opacity-1 p-sm mb-4',
                        )}
                    >
                        <CustomerSummary
                            data={data}
                            page="customer"
                            refreshData={() => request({ id })}
                            expanded={expanded}
                        />
                    </div>

                    <Tabs
                        defaultActiveKey={currentPage}
                        className="gap-2"
                        tabBarGutter={50}
                        onChange={e => {
                            navigate(`/customers/${id}/${e}`);
                            setCurrentPage(e);
                            setExpanded(e == 'overview');
                        }}
                        items={[
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <FileSearchOutlined />
                                        <Text className="uppercase">Overview</Text>
                                    </div>
                                ),
                                key: 'overview',
                                children: (
                                    <CustomerOverview
                                        data={data}
                                        id={id + ''}
                                        loading={loading}
                                        fetchCustomer={fetchCustomer}
                                    />
                                ),
                            },
                            {
                                label: (
                                    <div className="flex flex-row gap-0 items-center">
                                        <BulbOutlined />
                                        <Text className="uppercase">Trips</Text>
                                    </div>
                                ),
                                key: 'trip-history',
                                children: <CustomerTripHistory id={id} />,
                            },
                        ]}
                    />
                </div>
            </div>
        </DetailContentLayout>
    );
};

export default CustomerDetailedPage;
