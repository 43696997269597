import Modal from 'antd/lib/modal/Modal';
import { CustomNotification } from 'components/basic';
import useApi from 'hooks/useApi';
import React from 'react';
import { useParams } from 'react-router-dom';
import { deleteZone } from 'services/message.service';
interface DeleteZoneModalProps {
    modal: any;
    onSuccess?: () => void;
    layerId?: any;
    zoneId?: any;
    zoneName?: any;
}

const DeleteZoneModal: React.FC<DeleteZoneModalProps> = ({ modal, onSuccess, zoneId, zoneName, layerId }) => {
    let { id: paramId } = useParams();
    if (!layerId) {
        layerId = paramId;
    }
    const handleOnClose = () => {
        modal.close();
    };

    const { request, loading } = useApi({
        api: deleteZone,
    });

    const deleteZones = async (id: any, zoneId: any) => {
        const res = await request({
            id,
            zoneId,
        });
        const { error } = res;
        if (!error) {
            CustomNotification({
                type: 'success',
                message: 'Zone Deleted',
                description: <p>{zoneName} Zone deleted successfully</p>,
            });
            onSuccess?.();
            handleOnClose();
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: error.message,
            });
        }
    };

    const handleFormFinish = () => {
        if (layerId && zoneId) {
            deleteZones(layerId, zoneId);
        } else {
            CustomNotification({
                type: 'error',
                message: 'Error',
                description: 'No valid ID provided for deletion.',
            });
        }
    };

    return (
        <Modal
            {...modal}
            onCancel={handleOnClose}
            title="Delete Zones"
            onOk={handleFormFinish}
            okText="Yes"
            okButtonProps={{
                danger: true,
                loading: loading,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: loading,
            }}
            closeIcon={false}
            closable={false}
            maskClosable={false}
            escapeKey={false}
        >
            <p>
                Are you sure you want to delete the Zone "<b>{zoneName}</b>"? This action cannot be undone.
            </p>
        </Modal>
    );
};

export default DeleteZoneModal;
