import { Skeleton } from 'antd/lib';
import React from 'react';
import axios from 'axios';
import useMount from 'hooks/useMount';
import { PopupButton } from '@typeform/embed-react';
import { NotificationOutlined } from '@ant-design/icons';

const Footer = ({ loading }: any) => {
    const [loadingJoke, setLoadingJoke] = React.useState(false);
    const [joke, setJoke] = React.useState('');

    useMount(() => {
        if (!loading) {
            getJoke();
        }
    });

    const getJoke = async () => {
        setLoadingJoke(true);
        const api = axios.create({
            baseURL: 'https://icanhazdadjoke.com/',
        });
        try {
            const response = await api({
                // url: `/api/messages/public`,
                method: 'GET',
                headers: {
                    'content-type': 'application/json',
                    Accept: 'application/json',
                },
            });

            const { data } = response;
            if (data.joke) {
                setJoke(data.joke);
            }

            setLoadingJoke(false);

            return {
                data,
                error: null,
            };
        } catch (error: any) {
            if (axios.isAxiosError(error)) {
                const axiosError = error;

                const { response } = axiosError;

                let message = 'http request failed';

                if (response && response.statusText) {
                    message = response.statusText;
                }

                if (axiosError.message) {
                    message = axiosError.message;
                }

                if (response && response.data && response.data.message) {
                    message = response.data.message;
                }

                return {
                    data: null,
                    error: {
                        message,
                        status: response?.status,
                    },
                };
            }

            return {
                data: null,
                error: {
                    message: error.message,
                },
            };
        }
    };

    return (
        <div className="mt-12 bg-gray-50 p-8 flex">
            <div className="flex-1 p-8">
                <img
                    src="https://uploads-ssl.webflow.com/64471d61bdefef433f4dd90c/64471d8bc62d862756262513_Logo.svg"
                    className="mb-4 mix-blend-luminosity pr-8 w-48"
                />

                <p className="text-gray-500 m-0">Angkas Admin Portal &copy; 2023</p>
                <p className="text-gray-400 m-0 mt-2 cursor-pointer" onClick={getJoke}>
                    {joke}
                </p>

                {!loading && (
                    <div className="mt-8">
                        <PopupButton id="mzQu4KyJ" className="give-feedback-btn">
                            <div className="flex items-center gap-2">
                                <NotificationOutlined />
                                <span>Share Your Feedback</span>
                            </div>
                        </PopupButton>
                    </div>
                )}
            </div>

            <div className="flex-1 p-8">
                <Skeleton loading={loading} active />
                {!loading && (
                    <>
                        <p className="text-gray-400 m-0 uppercase">Quick Links</p>

                        <p className="text-gray-500 m-0 mt-2">Websites</p>
                        <a href="https://www.angkas.com/" className="text-gray-400 block font-light" target="_blank">
                            Angkas Website
                        </a>
                        <a
                            href="https://manager-hub.dev.angkas.com/"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Manager Hub
                        </a>
                        <a
                            href="https://onboarding-dot-aks-infra-production-24b23c8e.appspot.com/admin"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Onboarding Hub
                        </a>

                        <p className="text-gray-500 m-0 mt-2">Google Forms</p>
                        <a
                            href="https://forms.gle/vzbts59X184oykvY7"
                            target="_blank"
                            className="text-gray-400 block font-light"
                        >
                            Account Deletion Request (For DAX and CAX Users)
                        </a>
                    </>
                )}
            </div>
            <div className="flex-1 p-8">
                <Skeleton loading={loading} active />
                {!loading && (
                    <>
                        <p className="text-gray-400 m-0 uppercase">Learn</p>

                        <p className="text-gray-500 m-0 mt-2">Documentations</p>

                        <a
                            href="https://app.clickup.com/14274986/v/dc/dkmda-32145/dkmda-24085"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Angkas Admin Portal Manual
                        </a>
                        <a
                            href="https://docs.google.com/spreadsheets/d/1RqDRvuOKANb0MD6wBJGm7QpzOokE26FcYeGYkkPHHzo/edit#gid=1969103259"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Error Codes
                        </a>
                    </>
                )}
            </div>
            <div className="flex-1 p-8">
                <Skeleton loading={loading} active />
                {!loading && (
                    <>
                        <p className="text-gray-400 m-0 uppercase">Support</p>
                        <p className="text-gray-500 m-0 mt-2">Technical</p>
                        <a
                            href="https://app.clickup.com/14274986/v/fm/dkmda-12587"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Report an Issue
                        </a>
                        <a
                            href="https://app.clickup.com/14274986/v/li/152279431/104233165"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Request for Product Support
                        </a>
                        <a
                            href="https://app.clickup.com/14274986/v/fm/dkmda-13645"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Submit Ideas and Enhancements
                        </a>

                        <p className="text-gray-500 m-0 mt-2">Non-Technical Issues</p>
                        <a
                            href="https://angkas.freshdesk.com/support/tickets/new"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            Raise a Ticket in Freshdesk
                        </a>
                    </>
                )}
            </div>

            <div className="flex-1 p-8">
                <Skeleton loading={loading} active />
                {!loading && (
                    <>
                        <p className="text-gray-400 m-0 uppercase">Product</p>

                        <p className="text-gray-500 m-0 mt-2">Driver App (DAX)</p>

                        <a
                            href="https://play.google.com/store/apps/details?id=com.angkas.biker&hl=en&gl=US"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            View in Play Store
                        </a>

                        <p className="text-gray-500 m-0 mt-2">Customer Apps (CAX)</p>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.angkas.passenger&pli=1"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            View in Play Store (Android)
                        </a>
                        <a
                            href="https://apps.apple.com/ph/app/angkas-motorcycle-taxi-app/id1169344958"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            View in App Store (iOS)
                        </a>
                        <a
                            href="https://appgallery.huawei.com/app/C105993803"
                            className="text-gray-400 block font-light"
                            target="_blank"
                        >
                            View in Huawei Store (Huawei)
                        </a>
                    </>
                )}
            </div>
        </div>
    );
};

export default Footer;
