import React, { useCallback, useContext, useRef, useState } from 'react';
import { Button, Table } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import useApi from 'hooks/useApi';
import { updateBiker } from 'services/message.service';
import { downloadCSVFile } from 'services/file.service';
import papa from 'papaparse';
import { json2csv } from 'json-2-csv';
import { useNavigate } from 'react-router-dom';
import { Alert, message, notification } from 'antd/lib';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import { AuthUserContext } from 'components/context/AuthUserContext';
import { Accessibility, PagesEnum, getPermissions } from 'services/permission.service';

const BulkMotorcyleUpdatePage = () => {
    const [showDownloadBtn, setShowDownloadBtn] = useState(false);
    const [tableData, setTableData]: any = useState([]);
    const [erroNumber, setErrorNumber] = useState(0);
    const [csv, setCsv] = useState('');
    const [successNumber, setSuccessNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const token = JSON.parse(localStorage.getItem('userToken') || '{}');
    const hiddenFileInput = useRef(null);
    const navigate = useNavigate();
    const defaultBatchSize = 10;
    const sleepPerBatchMS = 1000;
    const userRole = useContext(AuthUserContext);
    const hasActionAccess = (page: string) => {
        return (
            getPermissions(userRole, page)?.includes(Accessibility.ALL) ||
            getPermissions(userRole, page)?.includes(Accessibility.EDIT)
        );
    };

    const { request } = useApi({
        api: updateBiker,
    });

    const uploadCsv = (csvResult: []) => {
        uploadCsvData(csvResult);
    };

    const delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

    const sliceIntoChunks = (arr: any[], chunkSize: number) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    };

    const validateCsvHeaders = (csvHeaders: string[]): boolean => {
        const expectedHeaders = [
            'driver_id',
            'motorcycle_brand',
            'motorcycle_model',
            'motorcycle_year',
            'piston_displacement',
            'transmission_type',
            'engine_number',
            'chassis_number',
            'plate_number',
            'registration_expiry_date',
            'drivers_license',
            'drivers_license_expiry_date',
        ];

        if (csvHeaders.length !== expectedHeaders.length) {
            message.error('Please upload a CSV file with a valid template.');
            return false;
        }

        for (let i = 0; i < expectedHeaders.length; i++) {
            if (csvHeaders[i] !== expectedHeaders[i]) {
                message.error(`Header mismatch: Expected "${expectedHeaders[i]}" but got "${csvHeaders[i]}"`);
                return false;
            }
        }

        return true;
    };

    const processMotorcycle = useCallback(
        async (motorcycle: any, failedRecords: any[]) => {
            const id = motorcycle.driver_id.trim();
            const emptyFields = Object.entries(motorcycle)
                .filter(([key, value]) => value === '' || value === null)
                .map(([key, value]) => key);

            if (emptyFields.length > 0) {
                failedRecords.push({
                    driver_id: id,
                    ...motorcycle,
                    remarks: pluralizeMessage(emptyFields),
                });
                return { apiRes: null, id };
            }
            const motorcycle_make = motorcycle.motorcycle_brand;
            delete motorcycle.driver_id;
            delete motorcycle.motorcycle_brand;
            const apiRes = await request({
                id,
                body: {
                    ...motorcycle,
                    motorcycle_make: motorcycle_make,
                    motorcycle_year: Number(motorcycle.motorcycle_year),
                    piston_displacement: Number(motorcycle.piston_displacement),
                },
            });
            return { apiRes, id, motorcycle_brand: motorcycle_make };
        },
        [request],
    );

    const processChunk = useCallback(
        async (chunk: any[], failedRecords: any[]) => {
            let result: any[] = [];
            let duplicates: any[] = [];
            const seenIds = new Set();

            await Promise.all(
                chunk.map(async (motorcycle: any) => {
                    if (motorcycle.driver_id !== '' || motorcycle.driver_id !== null) {
                        if (seenIds.has(motorcycle.driver_id)) {
                            duplicates.push(motorcycle);
                        } else {
                            seenIds.add(motorcycle.driver_id);
                        }
                    }

                    const { apiRes, id, motorcycle_brand } = await processMotorcycle(motorcycle, failedRecords);
                    if (apiRes?.error) {
                        handleError(apiRes, id, motorcycle, failedRecords, motorcycle_brand);
                    } else if (apiRes) {
                        result.push(apiRes);
                    }
                }),
            );

            return { result, duplicates };
        },
        [processMotorcycle],
    );

    const processCsvData = useCallback(
        async (csvResult: any[]) => {
            let chunks = sliceIntoChunks(csvResult, defaultBatchSize);
            let result: any[] = [];
            let failedRecords: any[] = [];
            let duplicates: any[] = [];

            try {
                for (let i = 0; i < chunks.length; i++) {
                    const chunkResults = await processChunk(chunks[i], failedRecords);

                    result = result.concat(chunkResults.result);
                    duplicates = duplicates.concat(chunkResults.duplicates);

                    await delay(sleepPerBatchMS);
                    updateProgress(i, chunks.length);
                }

                return { result, failedRecords, duplicates };
            } catch (error) {
                throw error;
            }
        },
        [processChunk],
    );

    const uploadCsvData = useCallback(
        async (csvResult: any[]) => {
            setTableData([]);
            setLoading(true);
            if (csvResult.length === 0) {
                message.error('No record found in the CSV file.');
            } else {
                notification.info({
                    key: 'progress',
                    message: 'Uploading',
                    description: '0% completed',
                });
                const { result, failedRecords, duplicates } = await processCsvData(csvResult);
                displayResults(result, failedRecords, duplicates);
            }
            setLoading(false);
        },
        [processCsvData],
    );

    const pluralizeMessage = (fields: string[]) => {
        const fieldCount = fields.length;
        if (fieldCount === 1) {
            return `Field ${fields[0]} is required.`;
        } else {
            return `Fields ${fields.join(', ')} are required.`;
        }
    };

    const handleError = (apiRes: any, id: string, motorcycle: any, failedRecords: any[], motorcycle_brand: any) => {
        if (apiRes.error) {
            failedRecords.push({
                driver_id: id,
                ...motorcycle,
                motorcycle_brand,
                remarks: `${apiRes.error.message}, status: ${apiRes.status}`,
            });
        }
    };

    const updateProgress = (currentIndex: number, totalChunks: number) => {
        notification.info({
            key: 'progress',
            message: 'Uploading',
            description: `${(((currentIndex + 1) / totalChunks) * 100).toFixed(2)}% completed`,
        });
    };

    const displayResults = async (result: any[], failedRecords: any[], duplicates: any[]) => {
        notification.destroy();

        if (duplicates.length > 0) {
            message.info('We detect duplicate rows. We will be using the most recent information.');
        }

        if (failedRecords.length === 0) {
            notification.success({
                message: 'Success',
                description: "Biker's motorcycle have been updated.",
            });
        } else if (result.length > 0) {
            notification.warning({
                message: 'Error',
                description: 'Some rows encountered error',
                duration: 3,
            });
            setShowDownloadBtn(true);
        } else {
            notification.error({
                message: 'Error',
                description: 'Error for all rows',
                duration: 3,
            });
            setShowDownloadBtn(true);
        }
        setErrorNumber(failedRecords.length);
        if (failedRecords.length > 0) {
            setShowDownloadBtn(true);
        }
        setSuccessNumber(result.length);
        setTableData(failedRecords);

        const csvData = await json2csv(failedRecords);
        setCsv(csvData);
    };

    const downloadFailedData = () => {
        const csvData = String(csv);
        downloadCSVFile(csvData, 'failed_mc_details');
    };

    const downloadTemplate = () => {
        let tempData = `driver_id,motorcycle_brand,motorcycle_model,motorcycle_year,piston_displacement,transmission_type,engine_number,chassis_number,plate_number,registration_expiry_date,drivers_license,drivers_license_expiry_date
09bcb2be-07a5-4127-bde3-a588678fedf20,Honda,CRF 150,2020,100,Automatic,MKS345634,KGG56345,POP101,2025-02-21,3103413598,2029-11-03`;

        downloadCSVFile(tempData, 'bulk_mc_update_template_v1');
    };

    const handleFile = (event: any) => {
        setShowDownloadBtn(false);
        papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: true,
            complete: function (result: any) {
                const max_rows = 3000;
                if (result.data.length > max_rows) {
                    // Maximum of 3000 rows per upload
                    notification.error({
                        message: 'Error',
                        description: `Maximum of ${max_rows} Rows per upload`,
                        duration: 3,
                    });
                    return;
                }

                const csvHeaders = result.meta.fields;
                const isValid = validateCsvHeaders(csvHeaders);
                if (isValid) {
                    let arrayObj = result.data.map((d: any) => {
                        return {
                            ...d,
                        };
                    });
                    uploadCsv(arrayObj);
                }
                event.target.value = null;
            },
        });
    };
    const handleFileUpload = (event: any) => {
        (hiddenFileInput?.current as any)?.click();
    };

    const columns = [
        {
            title: 'Driver ID',
            key: 'driver_id',
            render: (motorcycle: any) => motorcycle?.driver_id,
        },
        {
            title: 'Motorcycle Brand',
            key: 'motorcycle_brand',
            render: (motorcycle: any) => motorcycle?.motorcycle_brand,
        },
        {
            title: 'Motorcycle Model',
            key: 'motorcycle_model',
            render: (motorcycle: any) => motorcycle?.motorcycle_model,
        },
        {
            title: 'Motorcycle Year',
            key: 'motorcycle_year',
            render: (motorcycle: any) => motorcycle?.motorcycle_year,
        },
        {
            title: 'Piston Displacement',
            key: 'piston_displacement',
            render: (motorcycle: any) => motorcycle?.piston_displacement,
        },
        {
            title: 'Transmission Type',
            key: 'transmission_type',
            render: (motorcycle: any) => motorcycle?.transmission_type,
        },
        {
            title: 'Engine Number',
            key: 'engine_number',
            render: (motorcycle: any) => motorcycle?.engine_number,
        },
        {
            title: 'Chassis Number',
            key: 'chassis_number',
            render: (motorcycle: any) => motorcycle?.chassis_number,
        },
        {
            title: 'Plate Number',
            key: 'plate_number',
            render: (motorcycle: any) => motorcycle?.plate_number,
        },
        {
            title: 'OR Expiry Date',
            key: 'registration_expiry_date',
            render: (motorcycle: any) => moment(motorcycle?.registration_expiry_date).format('MMM D, YYYY'),
        },
        {
            title: "Driver's License No.",
            key: 'drivers_license',
            render: (motorcycle: any) => motorcycle?.drivers_license,
        },
        {
            title: 'License Expiry Date',
            key: 'drivers_license_expiry_date',
            render: (motorcycle: any) => moment(motorcycle?.drivers_license_expiry_date).format('MMM D, YYYY'),
        },
        {
            title: 'Remarks',
            key: 'remarks',
            render: (motorcycle: any) => motorcycle?.remarks,
        },
    ];

    return (
        <ListLayout goBackLink="/settings" title="Bulk Motorcycle Update">
            <div className="flex justify-between">
                <Alert
                    showIcon
                    type="warning"
                    message="Please make sure you follow the correct format before uploading the
                    CSV file."
                />
                <div className="flex flex-row gap-1">
                    <div className="flex flex-col">
                        {hasActionAccess(PagesEnum.DRIVERS) && (
                            <div>
                                <input
                                    hidden
                                    id="upload-file"
                                    type="file"
                                    accept=".csv"
                                    name="file"
                                    ref={hiddenFileInput}
                                    onChange={handleFile}
                                ></input>
                                <Button
                                    type="primary"
                                    onClick={handleFileUpload}
                                    icon={<UploadOutlined />}
                                    disabled={loading}
                                >
                                    Upload CSV
                                </Button>
                            </div>
                        )}
                    </div>
                    <div>
                        <Button
                            type="ghost"
                            target="_blank"
                            onClick={() => downloadTemplate()}
                            icon={<DownloadOutlined />}
                        >
                            Download Template
                        </Button>
                    </div>
                </div>
            </div>
            {showDownloadBtn ? (
                <div style={{ border: '1px solid #ADB6B9' }} className="flex justify-between items-center m-2 p-4">
                    <div>
                        <ul className="text-sm font-bold p-0">
                            Upload .csv error
                            <li className="block font-medium text-base">{erroNumber} Number of rows have error </li>
                            <li className="block font-medium text-base">{successNumber} Number of rows have success</li>
                        </ul>
                    </div>
                    <div>
                        <button
                            type="submit"
                            style={{
                                border: '1px solid #D9D9D9',
                                boxShadow: '0px 2px 0px 0px rgba(0, 0, 0, 0.02)',
                            }}
                            className="bg-inherit px-4 py-2"
                            onClick={() => downloadFailedData()}
                            id="text-button"
                        >
                            Download Failed Rows
                        </button>
                    </div>
                </div>
            ) : null}
            <Table
                columns={columns}
                loading={loading}
                // pagination={false}
                dataSource={tableData}
            />
        </ListLayout>
    );
};

export default BulkMotorcyleUpdatePage;
