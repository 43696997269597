import { Table, Text } from 'components/basic';
import ListLayout from 'components/layouts/ContentLayout/ListLayout';
import React, { useCallback, useState } from 'react';
import { Button, Tag, Tooltip } from 'antd/lib';
import { getUserManagementAuditLogs } from 'services/message.service';
import useApi from 'hooks/useApi';
import { Link } from 'react-router-dom';
import useFilter from 'hooks/useFilter';
import useMount from 'hooks/useMount';
import useModal from 'hooks/useModal';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import UserManagementAuditLogsFilter from './UserManagementAuditLogsFilter';
import { convertTimestampToDateTime } from 'services/date.service';
import moment from 'moment';
import MarkdownRenderer from 'components/basic/MarkdownRenderer/MarkdownRenderer';
import Modal from 'antd/lib/modal/Modal';

const UserManagementAuditLogsPage = () => {
    const viewDetailModal = useModal();
    const [data, setData] = useState([]);
    const [showType, setShowType] = useState(false);
    const [pagination, setPagination]: any = useState({
        defaultCurrent: 1,
        defaultPageSize: 10,
    });

    const { modifyFilters, requestState } = useFilter({
        page_size: 10,
        page: 1,
        search_by: '',
        search_key: '',
        sort_key: 'first_name',
        sort_by: 'asc',
        operator_id: '',
        data_type: '',
        data_change: '',
    });

    const { request, loading } = useApi({
        api: getUserManagementAuditLogs,
    });

    const columns = [
        {
            title: 'Log ID',
            render: (user: any) => (
                <Text type="uuid" className="w-56">
                    {user.id}
                </Text>
            ),
        },
        {
            title: 'Description',
            key: 'description',
            render: (user: any) => <MarkdownRenderer markdownContent={user.description || 'No Description'} />,
        },
        {
            title: 'Operator',
            key: 'operator',
            render: (user: any) => (
                <div className="flex items-center">
                    <Text>{`${user.operator_name}`}</Text>
                </div>
            ),
        },
        {
            title: 'Action',
            render: (user: any) => {
                const actionColors: Record<string, string> = {
                    DELETE: 'text-red-500',
                    CREATE: 'text-green-500',
                    UPDATE: 'text-orange-500',
                    READ: 'text-cyan-500',
                    VIEW_CUSTOMERS: 'text-blue-500',
                    VIEW_CUSTOMER: 'text-blue-500',
                    VIEW_LIST: 'text-orange-500',
                    VIEW: 'text-orange-600',
                };
                const color = actionColors[user.data_change] || 'text-gray-400';
                return <Text color={color}>{user.data_change || 'No Action'}</Text>;
            },
        },
        {
            title: 'Changes',
            key: 'changes',
            render: (data: any) => <MarkdownRenderer markdownContent={data.trace || 'No Changes'} />,
        },
        {
            title: 'Timestamp',
            key: 'timestamp',
            render: (user: any) => (
                <Tooltip title={convertTimestampToDateTime(user.created_at)}>
                    <span color={user.timestamp ? 'text-black' : 'text-gray-400'}>
                        {moment.utc(user.created_at).add(8, 'hour').format('MMMM DD, YYYY hh:mm:ss A') ||
                            'No Timestamp'}
                    </span>
                </Tooltip>
            ),
        },
    ];

    const fetchList = useCallback(
        async (requestState: {}) => {
            try {
                const result = await request(requestState);
                let d = result.data;
                if (d.status == 'success') {
                    let logs = d.data.audit_logs.map((c: any) => {
                        return {
                            ...c,
                            key: c.id,
                        };
                    });
                    setData(logs);
                    const pagination = d.data.pagination;
                    setPagination({
                        current: pagination.page,
                        total: pagination.total_count,
                        pageSize: pagination.page_size,
                    });
                } else {
                    // TODO: error screen
                }
            } catch (error) {
                throw error;
            }
        },
        [request],
    );

    useMount(() => {
        fetchList(requestState);
    });

    const onTableChange = useCallback(
        async (pagination: any, filters: any, sorter: any) => {
            const { current, pageSize } = pagination;
            const { field, order } = sorter;

            const { requestState } = await modifyFilters({
                ...filters,
                page: current,
                page_size: pageSize,
                sort_key: field,
                sort_by: order === 'ascend' ? 'asc' : 'desc',
            });

            await fetchList(requestState ?? {});
        },
        [fetchList, modifyFilters],
    );

    const onFilterSubmit = useCallback(
        async (filter: any) => {
            const { requestState } = await modifyFilters({
                page: 1,
                operator_id: filter.operatorId,
                data_type: filter.dataType,
                data_change: filter.dataChange,
            });

            await fetchList(requestState ?? {});
        },
        [fetchList, modifyFilters],
    );

    if (showType) {
        const lastIndex = columns.length;
        columns.splice(lastIndex - 1, 0, {
            title: 'Type',
            key: 'type',
            render: (user: any) => {
                const typeColors: Record<string, string> = {
                    OPERATOR: 'geekblue',
                    AUDIT_LOG: 'orange',
                    DRIVER: 'green',
                    DRIVER_PHOTO: 'volcano',
                    CUSTOMER: 'cyan',
                    FLEET: 'magenta',
                    DEVICE: 'gold',
                };
                const color = typeColors[user.data_type] || 'gray';
                return user.data_type ? (
                    <Tag color={color}>{user.data_type}</Tag>
                ) : (
                    <Text color="text-gray-400">No Type</Text>
                );
            },
        });
    }

    return (
        <div className="flex flex-col gap-2">
            <div className="flex">
                <Link className="text-gray-500 text-sm cursor-pointer hover:text-blue" to={'/logs'}>
                    ← Back to Logs
                </Link>
            </div>
            <ListLayout
                title="User Management Audit Logs"
                searchComponent={<UserManagementAuditLogsFilter onFilterSubmit={onFilterSubmit} />}
                actionComponent={
                    <Button
                        icon={!showType ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                        onClick={() => setShowType(!showType)}
                    >
                        {!showType ? 'Show Data Type' : 'Hide Data Type'}
                    </Button>
                }
            >
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        ...pagination,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total: any) => `${total?.toLocaleString()} items`,
                    }}
                    onChange={onTableChange}
                    className="audit-logs-table"
                />
                <Modal {...viewDetailModal} onOk={() => viewDetailModal.close()} />
            </ListLayout>
        </div>
    );
};

export default UserManagementAuditLogsPage;
