import React, { FunctionComponent, useCallback, useState } from 'react';
import styles from './LoginPage.module.scss';
import { ButtonProps } from 'antd/lib';
import classnames from 'clsx';
import { useAuth0 } from '@auth0/auth0-react';
import LoginLayout from 'components/layouts/LoginLayout/LoginLayout';
import useMount from 'hooks/useMount';

const LoginPage: FunctionComponent<ButtonProps> = props => {
    const { isLoading, loginWithRedirect } = useAuth0();
    const [isRedirecting, setIsRedirecting] = useState(false);

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const aapUrl = process.env.REACT_APP_AAP_URL || '';
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

    const handleLogin = async () => {
        await loginWithRedirect({
            appState: {
                returnTo: '/',
            },
            authorizationParams: {
                prompt: 'select_account',
            },
        });
    };

    const redirectWithURLParams = useCallback(
        ({ organization, invitation, organizationName }: any) => {
            if (domain && organization) {
                setIsRedirecting(true);
                const callbackUrl = encodeURI(aapUrl);
                const redirectUri =
                    `https://${domain}/authorize?organization=${organization}&invitation=${invitation}` +
                    `&organization_name=${organizationName}&client_id=${clientId}&response_type=token&redirect_uri=${callbackUrl}`;

                window.location.href = redirectUri;
            }
        },
        [domain, clientId, aapUrl, setIsRedirecting],
    );

    useMount(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const organization = urlParams.get('organization');
        const invitation = urlParams.get('invitation');
        const organizationName = urlParams.get('organization_name');

        if (organization && invitation && organizationName) {
            redirectWithURLParams({ organization, invitation, organizationName });
        }
    });

    return (
        <LoginLayout
            component={
                <div>
                    <h3>Driving the Wheels of Support!</h3>
                    <a href="" onClick={handleLogin}>
                        <img src="/images/google-logo.png" className={classnames(styles.GoogleLogo)} />
                        Login with Google
                    </a>
                </div>
            }
            isLoading={isLoading || isRedirecting}
        />
    );
};

export default LoginPage;
